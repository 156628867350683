// Role-based menu visibility configuration
export const MENU_PERMISSIONS = {
  dashboard: ['admin', 'sales-manager'],
  leads: ['admin', 'sales-manager', 'sales-user'],
  proposals: ['admin', 'sales-manager', 'sales-user'],
  'action-items': ['admin', 'sales-manager', 'sales-user'],
  inventory: ['admin', 'sales-manager', 'sales-user', 'broker', 'operations-user','operator-owner','operator-manager'],
  operators: ['admin', 'sales-manager', 'sales-user', 'broker', 'operations-user','operator-owner','operator-manager'],
  centers: ['admin', 'sales-manager', 'sales-user', 'broker', 'operations-user','operator-owner','operator-manager'],
  inventories: ['admin', 'sales-manager', 'sales-user', 'broker', 'operations-user','operator-owner','operator-manager'],
  settings: ['admin', 'operations-user'],
  calendar: ['admin', 'sales-manager', 'sales-user'],
  notifications: ['admin', 'sales-manager', 'sales-user']
};

// Action-level permissions configuration
export const ACTION_PERMISSIONS = {
  operators: {
    new_inventory: ['admin', 'operations-user'],
    edit: ['admin', 'operations-user', 'sales-manager','operator-owner'],
    import: ['admin', 'operations-user', 'sales-manager','operator-owner'],
    delete: ['admin', 'operations-user','operator-owner'],
  },
  centers: {
    add_center: ['admin', 'operations-user', 'sales-manager','operator-owner','operator-manager'],
    edit: ['admin', 'operations-user', 'sales-manager','operator-owner','operator-manager'],
    delete: ['admin', 'operations-user','operator-owner'],
  },
  inventories: {
    add_inventory: ['admin', 'operations-user', 'sales-manager','operator-owner','operator-manager'],
    edit: ['admin', 'operations-user', 'sales-manager','operator-owner','operator-manager'],
    delete: ['admin', 'operations-user','operator-owner'],
    add_to_proposal: ['admin', 'sales-manager', 'sales-user','operator-owner'],
    check_availability: ['admin', 'sales-manager', 'sales-user']
  }
};

/**
 * Check if a user has permission to view a specific menu item
 * @param {string} menuPath - The path/identifier of the menu item
 * @param {string} userRole - The role of the current user
 * @returns {boolean} - Whether the user has permission to view the menu
 */
export const hasMenuPermission = (menuPath, userRole) => {
  if (!menuPath || !userRole) return false;
  
  const allowedRoles = MENU_PERMISSIONS[menuPath];
  return allowedRoles ? allowedRoles.includes(userRole) : true;
};

/**
 * Check if a user has permission to perform a specific action
 * @param {string} screen - The screen/section identifier
 * @param {string} action - The action to check permission for
 * @param {string} userRole - The role of the current user
 * @returns {boolean} - Whether the user has permission to perform the action
 */
export const hasActionPermission = (screen, action, userRole) => {
  if (!screen || !action || !userRole) return false;
  
  const screenPermissions = ACTION_PERMISSIONS[screen];
  if (!screenPermissions) return true;
  
  const allowedRoles = screenPermissions[action];
  return allowedRoles ? allowedRoles.includes(userRole) : true;
};
