import React, { useState, useCallback,useEffect, useMemo } from 'react';
import { GoogleMap, MarkerF, InfoWindowF, MarkerClusterer } from '@react-google-maps/api';
import { useGoogleMaps } from '../../contexts/GoogleMapsContext';
import {
  Box,
  Typography,
  Button,
  Paper,
  IconButton,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Slider,
  Menu,
  MenuItem,
  Grid
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';

const containerStyle = {
  width: '100%',
  height: '100%'  // Use parent's height
};

const mapStyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ADD8E6"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];

const mapOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true
};

// Default center (Bangalore)
const defaultCenter = {
  lat: 12.9716,
  lng: 77.5946
};

const CentersMap = ({ centers, operatorData }) => {
  const [zoom, setZoom] = useState(13);
  const { isLoaded } = useGoogleMaps();
  const [map, setMap] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [selectedCenterState, setSelectedCenterState] = useState(selectedCenter);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [showFilters, setShowFilters] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [filteredCenters, setFilteredCenters] = useState([]);
  const navigate = useNavigate();

  // Filter states
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedOperators, setSelectedOperators] = useState([]);
  const [selectedBuildingTypes, setSelectedBuildingTypes] = useState([]);
  const [selectedBuildingGrades, setSelectedBuildingGrades] = useState([]);
  const [selectedMicromarkets, setSelectedMicromarkets] = useState([]);
  const [priceRange, setPriceRange] = useState([4000, 40000]);
  const [anchorEl, setAnchorEl] = useState(null);

  // State for summary card visibility
  const [visibleSummaries, setVisibleSummaries] = useState({
    cities: true,
    operators: true,
    buildingGrades: true
  });

  // Settings panel visibility
  const [showSettings, setShowSettings] = useState(false);

  // Get unique values for filters
  const cities = [...new Set(centers.map(center => center.city))].sort();
  const operators = [...new Set(centers.map(center => center.operatorName))].sort();
  const buildingTypes = [...new Set(centers.map(center => center.buildingType))].sort();
  const buildingGrades = [...new Set(centers.map(center => center.buildingGrade))].sort();
  const micromarkets = [...new Set(centers.map(center => center.micromarket))].sort();
  const maxPrice = 40000;
  const formatPrice = (price) =>
    price !== undefined && price !== null
      ? new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
      }).format(price).replace("₹", "").trim()
      : "";

  // Calculate city-wise counts
  const cityCounts = centers.reduce((acc, center) => {
    acc[center.city] = (acc[center.city] || 0) + 1;
    return acc;
  }, {});

  const operatorCounts = centers.reduce((acc, center) => {
    if (center.operatorName) {
      acc[center.operatorName] = (acc[center.operatorName] || 0) + 1;
    }
    return acc;
  }, {});

  const createCircularIcon = (iconUrl) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      canvas.width = 20;
      canvas.height = 20;
      const ctx = canvas.getContext('2d');

      // Draw white circle background
      ctx.beginPath();
      ctx.arc(10, 10, 9, 0, 2 * Math.PI);
      ctx.fillStyle = '#ffffff';
      ctx.fill();
      ctx.strokeStyle = '#FF0000';
      ctx.lineWidth = 1;
      ctx.stroke();

      // Create image element
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = iconUrl;

      img.onload = () => {
        ctx.save();
        ctx.beginPath();
        ctx.arc(10, 10, 8, 0, 2 * Math.PI);
        ctx.clip();
        ctx.drawImage(img, 2, 2, 16, 16);
        ctx.restore();

        resolve(canvas.toDataURL());
      };

      img.onerror = () => {
        resolve(null);
      };
    });
  };

  // State to store marker icons
  const [markerIcons, setMarkerIcons] = useState({});

  useEffect(() => {
    const loadIcons = async () => {
      // Add a check to ensure filteredCenters and operatorData are defined
      if (!filteredCenters || !operatorData) {
        console.warn('filteredCenters or operatorData is undefined');
        return;
      }

      const iconPromises = filteredCenters.map(async (center) => {
        // Find the operator data for this center using multiple matching strategies
        let operator = null;

        // Strategy 1: Match by operatorId
        if (center.operatorId) {
          operator = operatorData.find(op => op.id === center.operatorId);
          if (operator) console.log('Matched by operatorId:', operator.id);
        }

        // Strategy 2: Match by operatorName
        if (!operator && center.operatorName) {
          operator = operatorData.find(op =>
            op.operatorName === center.operatorName ||
            op.name === center.operatorName
          );
          if (operator) console.log('Matched by operatorName:', operator.operatorName || operator.name);
        }

        // Strategy 3: Match by operator field
        if (!operator && center.operator) {
          operator = operatorData.find(op =>
            op.operatorName === center.operator ||
            op.name === center.operator
          );
          if (operator) console.log('Matched by operator field:', operator.operatorName || operator.name);
        }


        if (operator?.icons?.[0]?.url) {
          try {
            const circularIcon = await createCircularIcon(operator.icons[0].url);
            return {
              [center.id]: circularIcon
            };
          } catch (error) {
            console.error(`Error creating icon for center ${center.id}:`, error);
            return null;
          }
        }
        return null;
      });

      try {
        const resolvedIcons = await Promise.all(iconPromises);
        const iconMap = resolvedIcons.reduce((acc, item) => {
          if (item) return { ...acc, ...item };
          return acc;
        }, {});

        setMarkerIcons(iconMap);
      } catch (error) {
        console.error('Error resolving icons:', error);
      }
    };

    loadIcons();
  }, [filteredCenters, operatorData]);

  // Calculate counts from filtered centers
  const calculateCounts = (centersArray) => {
    return {
      cities: centersArray.reduce((acc, center) => {
        acc[center.city] = (acc[center.city] || 0) + 1;
        return acc;
      }, {}),
      operators: centersArray.reduce((acc, center) => {
        if (center.operatorName) {
          acc[center.operatorName] = (acc[center.operatorName] || 0) + 1;
        }
        return acc;
      }, {}),
      buildingGrades: centersArray.reduce((acc, center) => {
        if (center.buildingGrade) {
          acc[center.buildingGrade] = (acc[center.buildingGrade] || 0) + 1;
        }
        return acc;
      }, {})
    };
  };

  // Get total counts for comparison
  const totalCounts = calculateCounts(centers);

  // Get filtered counts
  const filteredCounts = calculateCounts(centers.filter(center => {
    // Convert listing price to number and handle null/undefined
    const centerPrice = Number(center.listPrice) || 0;

    const matchesCity = selectedCities.length === 0 || selectedCities.includes(center.city);
    const matchesOperator = selectedOperators.length === 0 || selectedOperators.includes(center.operatorName);
    const matchesBuildingType = selectedBuildingTypes.length === 0 || selectedBuildingTypes.includes(center.buildingType);
    const matchesBuildingGrade = selectedBuildingGrades.length === 0 || selectedBuildingGrades.includes(center.buildingGrade);
    const matchesMicromarket = selectedMicromarkets.length === 0 || selectedMicromarkets.includes(center.micromarket);
    const matchesPrice = centerPrice >= priceRange[0] && centerPrice <= priceRange[1];

    return matchesCity && matchesOperator && matchesBuildingType &&
      matchesBuildingGrade && matchesMicromarket && matchesPrice;
  }));

  // Filter handlers
  const handleCityToggle = (city) => {
    setSelectedCities(prev =>
      prev.includes(city) ? prev.filter(c => c !== city) : [...prev, city]
    );
  };

  const handleOperatorToggle = (operator) => {
    setSelectedOperators(prev =>
      prev.includes(operator) ? prev.filter(o => o !== operator) : [...prev, operator]
    );
  };

  const handleBuildingTypeToggle = (type) => {
    setSelectedBuildingTypes(prev =>
      prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
    );
  };

  const handleBuildingGradeToggle = (grade) => {
    setSelectedBuildingGrades(prev =>
      prev.includes(grade) ? prev.filter(g => g !== grade) : [...prev, grade]
    );
  };

  const handleMicromarketToggle = (micromarket) => {
    setSelectedMicromarkets(prev =>
      prev.includes(micromarket) ? prev.filter(m => m !== micromarket) : [...prev, micromarket]
    );
  };

  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleClearFilters = () => {
    setSelectedCities([]);
    setSelectedOperators([]);
    setSelectedBuildingTypes([]);
    setSelectedBuildingGrades([]);
    setSelectedMicromarkets([]);
    setPriceRange([4000, 40000]);
  };

  // Color mapping for operators
  const operatorColors = useCallback(() => {
    const colors = [
      '#1976d2', // Blue
      '#2e7d32', // Green
      '#d32f2f', // Red
      '#ed6c02', // Orange
      '#9c27b0', // Purple
      '#0288d1', // Light Blue
      '#388e3c', // Light Green
      '#f44336', // Light Red
      '#ff9800', // Deep Orange
      '#673ab7'  // Deep Purple
    ];

    const uniqueOperators = [...new Set(centers.map(center => center.operatorName))];
    return Object.fromEntries(
      uniqueOperators.map((operator, index) => [
        operator,
        colors[index % colors.length]
      ])
    );
  }, [centers]);

  // Filter centers based on selected filters
  useEffect(() => {
    console.log('Filtering centers:', {
      totalCenters: centers.length,
      selectedCities,
      selectedOperators,
      selectedBuildingTypes,
      selectedBuildingGrades,
      selectedMicromarkets,
      priceRange
    });

    const filteredCentersTemp = centers.filter(center => {
      // Convert listing price to number and handle null/undefined
      const centerPrice = Number(center.listPrice) || 0;

      const matchesCity = selectedCities.length === 0 || selectedCities.includes(center.city);
      const matchesOperator = selectedOperators.length === 0 || selectedOperators.includes(center.operatorName);
      const matchesBuildingType = selectedBuildingTypes.length === 0 || selectedBuildingTypes.includes(center.buildingType);
      const matchesBuildingGrade = selectedBuildingGrades.length === 0 || selectedBuildingGrades.includes(center.buildingGrade);
      const matchesMicromarket = selectedMicromarkets.length === 0 || selectedMicromarkets.includes(center.micromarket);
      const matchesPrice = centerPrice >= priceRange[0] && centerPrice <= priceRange[1];
      console.log("Matching:",matchesCity,matchesOperator,matchesBuildingType,matchesBuildingGrade,matchesMicromarket,matchesPrice);
      return matchesCity && matchesOperator && matchesBuildingType &&
        matchesBuildingGrade && matchesMicromarket && matchesPrice;
    });

    console.log('Filtered centers:', filteredCentersTemp.length);
    setFilteredCenters(filteredCentersTemp);
  }, [centers, selectedCities, selectedOperators, selectedBuildingTypes, selectedBuildingGrades, selectedMicromarkets, priceRange]);

  useEffect(() => {
    if (filteredCenters.length > 0 && map) {
      const bounds = new window.google.maps.LatLngBounds();

      filteredCenters.forEach(center => {
        if (center.latitude && center.longitude) {
          bounds.extend({
            lat: Number(center.latitude),
            lng: Number(center.longitude)
          });
        }
      });

      map.fitBounds(bounds);
    }
  }, [filteredCenters, map]);

  const onLoad = (map) => {
    if (centers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      centers.forEach(center => {
        if (center.latitude && center.longitude) {
          bounds.extend({
            lat: Number(center.latitude),
            lng: Number(center.longitude)
          });
        }
      });

      map.fitBounds(bounds);

      // Add a small zoom out to show all markers with some padding
      // const listener = window.google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
      //   map.setZoom(map.getZoom() - 1);
      // });

      // return () => {
      //   window.google.maps.event.removeListener(listener);
      // };
    }
    setMap(map);
  };

  const onUnmount = (map) => {
    setMap(null);
  };

  const SummaryCard = ({ title, count, totalCount, onClick }) => (
    <Paper
      elevation={3}
      sx={{
        width: '120px',
        height: '80px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 1,
        flexShrink: 0,
        bgcolor: count === totalCount ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.6)',
        backdropFilter: 'blur(5px)',
        transition: 'all 0.2s',
        '&:hover': {
          transform: 'scale(1.05)',
          cursor: 'pointer',
          bgcolor: 'rgba(255, 255, 255, 0.95)',
        }
      }}
      onClick={onClick}
    >
      <Typography variant="subtitle3" sx={{
        fontWeight: 'medium',
        textAlign: 'center',
        color: 'text.primary'
      }}>
        {title}
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h4" sx={{
          fontWeight: 'bold',
          color: 'text.primary'
        }}>
          {count}
        </Typography>

      </Box>
    </Paper>
  );

  const Divider = () => (
    <Box
      sx={{
        borderLeft: '2px solid rgba(255,255,255,0.3)',
        height: 80,
        mx: 2,
        flexShrink: 0
      }}
    />
  );

  return isLoaded ? (
    <Box sx={{
      width: '100%',
      height: '100%',
      position: 'relative',
      ...(isFullscreen && {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        bgcolor: 'background.paper'
      })
    }}>
      {/* Filter toggle button */}
      <IconButton
        onClick={() => setShowFilters(!showFilters)}
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          zIndex: 2,
          bgcolor: 'background.paper',
          boxShadow: 2,
          '&:hover': {
            bgcolor: 'background.paper',
          }
        }}
      >
        <FilterAltIcon />
      </IconButton>

      {/* Settings button */}
      <IconButton
        onClick={() => setShowSettings(!showSettings)}
        sx={{
          position: 'absolute',
          top: 10,
          left: 60,
          zIndex: 2,
          bgcolor: 'background.paper',
          boxShadow: 2,
          '&:hover': {
            bgcolor: 'background.paper',
          }
        }}
      >
        <SettingsIcon />
      </IconButton>

      {/* Settings panel */}
      {showSettings && (
        <Paper
          sx={{
            position: 'absolute',
            top: 60,
            left: 60,
            p: 2,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(5px)',
            borderRadius: 1,
            boxShadow: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            zIndex: 2
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="subtitle2">
              Summary Cards
            </Typography>
            <IconButton size="small" onClick={() => setShowSettings(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleSummaries.cities}
                  onChange={(e) => setVisibleSummaries(prev => ({
                    ...prev,
                    cities: e.target.checked
                  }))}
                  size="small"
                />
              }
              label="Cities"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleSummaries.operators}
                  onChange={(e) => setVisibleSummaries(prev => ({
                    ...prev,
                    operators: e.target.checked
                  }))}
                  size="small"
                />
              }
              label="Operators"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleSummaries.buildingGrades}
                  onChange={(e) => setVisibleSummaries(prev => ({
                    ...prev,
                    buildingGrades: e.target.checked
                  }))}
                  size="small"
                />
              }
              label="Building Grades"
            />
          </FormGroup>
        </Paper>
      )}

      {/* Fullscreen toggle button */}
      <IconButton
        onClick={handleFullscreenToggle}
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 2,
          bgcolor: 'background.paper',
          boxShadow: 2,
          '&:hover': {
            bgcolor: 'background.paper',
          }
        }}
      >
        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </IconButton>

      {/* Floating filter panel */}
      {showFilters && (
        <Paper sx={{
          position: 'absolute',
          top: 60,
          left: 10,
          p: 3,
          maxWidth: 800,
          maxHeight: '80vh',
          overflowY: 'auto',
          zIndex: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          backdropFilter: 'blur(8px)',
          '&:before': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backdropFilter: 'blur(8px)',
            zIndex: -1,
          }
        }}>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Filters</Typography>
            <Box>
              <Button
                size="small"
                onClick={handleClearFilters}
                sx={{ mr: 1 }}
              >
                Clear All
              </Button>
              <IconButton size="small" onClick={() => setShowFilters(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Grid container spacing={1}>


            <Grid item xs={12} md={4} sx={{ p: 2 }}>
              <Box sx={{ p: 2, borderRadius: 1 }}>
                <Typography variant="subtitle1" gutterBottom>Micromarkets</Typography>
                <FormGroup>
                  {micromarkets.map(micromarket => (
                    <FormControlLabel
                      key={micromarket}
                      control={
                        <Checkbox
                          checked={selectedMicromarkets.includes(micromarket)}
                          onChange={() => handleMicromarketToggle(micromarket)}
                          size="small"
                        />
                      }
                      label={<Typography variant="body2">{micromarket}</Typography>}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ p: 2 }}>
              <Box sx={{ p: 2, borderRadius: 1 }}>

                <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>Building Types</Typography>
                <FormGroup>
                  {buildingTypes.map(type => (
                    <FormControlLabel
                      key={type}
                      control={
                        <Checkbox
                          checked={selectedBuildingTypes.includes(type)}
                          onChange={() => handleBuildingTypeToggle(type)}
                          size="small"
                        />
                      }
                      label={<Typography variant="body2">{type}</Typography>}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ p: 2 }}>
              <Box sx={{ p: 2, borderRadius: 1 }}>

                <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>Building Grades</Typography>
                <FormGroup>
                  {buildingGrades.map(grade => (
                    <FormControlLabel
                      key={grade}
                      control={
                        <Checkbox
                          checked={selectedBuildingGrades.includes(grade)}
                          onChange={() => handleBuildingGradeToggle(grade)}
                          size="small"
                        />
                      }
                      label={<Typography variant="body2">{grade}</Typography>}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ p: 2 }}>
              <Box sx={{ p: 2, borderRadius: 1 }}>
                <Typography variant="subtitle1" gutterBottom>Price Range</Typography>
                <Box sx={{ px: 2, mt: 2 }}>
                  <Slider
                    value={priceRange}
                    onChange={handlePriceRangeChange}
                    valueLabelDisplay="auto"
                    min={4000}
                    max={40000}
                    valueLabelFormat={value => `${value}`}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Typography variant="caption">4000</Typography>
                    <Typography variant="caption">40000</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Map */}
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: isFullscreen ? '100%' : '90%'
        }}
        center={mapCenter}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={mapOptions}
      >
        {filteredCenters.length > 0 && (
          <MarkerClusterer
            key={`cluster-${filteredCenters.length}-${selectedCities.join()}-${selectedOperators.join()}-${selectedBuildingTypes.join()}-${selectedBuildingGrades.join()}-${selectedMicromarkets.join()}-${priceRange.join()}`}
            enableRetinaIcons
            gridSize={60}
            maxZoom={15}
            minimumClusterSize={2}
          >
            {(clusterer) => (
              <>
                {filteredCenters.map((center, index) => {
                  if (!center.latitude || !center.longitude) return null;
                  const markerIcon = markerIcons[center.id] ? {
                    url: markerIcons[center.id],
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(40, 40)
                  } : {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillColor: '#FF0000',
                    fillOpacity: 1,
                    strokeWeight: 1,
                    strokeColor: '#FFFFFF',
                    scale: 10
                  };
                  return (
                    <MarkerF
                      key={`${center.id}-${index}`}
                      position={{
                        lat: Number(center.latitude),
                        lng: Number(center.longitude)
                      }}
                      onClick={() => setSelectedCenterState(center)}
                      label={{
                        text: center.centerName.charAt(0),
                        color: 'white',
                        fontWeight: 'bold'
                      }}
                      icon={{
                        path: window.google.maps.SymbolPath.CIRCLE,
                        fillColor: operatorColors()[center.operatorName],
                        fillOpacity: 1,
                        strokeWeight: 0,
                        scale: 12
                      }}
                      clusterer={clusterer}
                    />
                  );
                })}
              </>
            )}
          </MarkerClusterer>
        )}
        {selectedCenterState && (
          <InfoWindowF
            position={{
              lat: Number(selectedCenterState.latitude),
              lng: Number(selectedCenterState.longitude)
            }}
            onCloseClick={() => setSelectedCenterState(null)}
          >
            <Box sx={{ p: 1, maxWidth: 200 }}>
              {selectedCenterState.photos?.[0] && (
                <Box
                  component="img"
                  src={selectedCenterState.photos[0].url}
                  alt={selectedCenterState.centerName}
                  sx={{
                    width: '100%',
                    height: 120,
                    objectFit: 'cover',
                    borderRadius: '10px',
                    mb: 1
                  }}
                />
              )}
              <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 1 }}>
                {selectedCenterState.centerName}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Operator: {selectedCenterState.operatorName}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                List Price: {formatPrice(selectedCenterState.listPrice)}
              </Typography>
            </Box>
          </InfoWindowF>
        )}
      </GoogleMap>

      {/* Summary section */}
      {isFullscreen && (
        <>
          {/* Summary cards */}
          <Box sx={{
            position: 'absolute',
            bottom: 20,
            left: 20,
            display: 'flex',
            gap: 2,
            p: 2,
            overflowX: 'auto',
            maxWidth: 'calc(100% - 120px)',
            '&::-webkit-scrollbar': {
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              bgcolor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              bgcolor: 'rgba(255, 255, 255, 0.5)',
              borderRadius: '4px',
            },
            '&::before': {
              content: '"Summary"',
              position: 'absolute',
              top: -25,
              left: 8,
              color: 'white',
              textShadow: '0 0 4px rgba(0,0,0,0.5)',
              fontWeight: 500
            }
          }}>
            {/* City cards */}
            {visibleSummaries.cities && (
              <>
                {Object.entries(totalCounts.cities).sort().map(([city, totalCount]) => (
                  <SummaryCard
                    key={`city-${city}`}
                    title={city}
                    count={filteredCounts.cities[city] || 0}
                    totalCount={totalCount}
                    onClick={() => {
                      const centerInCity = centers.find(c => c.city === city);
                      if (centerInCity) {
                        setMapCenter({
                          lat: Number(centerInCity.latitude),
                          lng: Number(centerInCity.longitude)
                        });
                        map?.setZoom(13);
                      }
                    }}
                  />
                ))}
                {(visibleSummaries.operators || visibleSummaries.buildingGrades) && <Divider />}
              </>
            )}

            {/* Operator cards */}
            {visibleSummaries.operators && (
              <>
                {Object.entries(totalCounts.operators).sort().map(([operator, totalCount]) => (
                  <SummaryCard
                    key={`operator-${operator}`}
                    title={operator}
                    count={filteredCounts.operators[operator] || 0}
                    totalCount={totalCount}
                    onClick={() => {
                      setSelectedOperators(prev => {
                        const newOperators = new Set(prev);
                        newOperators.clear();
                        newOperators.add(operator);
                        return newOperators;
                      });
                      const centerByOperator = centers.find(c => c.operatorName === operator);
                      if (centerByOperator) {
                        setMapCenter({
                          lat: Number(centerByOperator.latitude),
                          lng: Number(centerByOperator.longitude)
                        });
                        map?.setZoom(13);
                      }
                    }}
                  />
                ))}
                {visibleSummaries.buildingGrades && <Divider />}
              </>
            )}

            {/* Building Grade cards */}
            {visibleSummaries.buildingGrades && (
              Object.entries(totalCounts.buildingGrades).sort().map(([grade, totalCount]) => (
                <SummaryCard
                  key={`grade-${grade}`}
                  title={grade}
                  count={filteredCounts.buildingGrades[grade] || 0}
                  totalCount={totalCount}
                  onClick={() => {
                    setSelectedBuildingGrades(prev => {
                      const newGrades = new Set(prev);
                      newGrades.clear();
                      newGrades.add(grade);
                      return newGrades;
                    });
                    const centerByGrade = centers.find(c => c.buildingGrade === grade);
                    if (centerByGrade) {
                      setMapCenter({
                        lat: Number(centerByGrade.latitude),
                        lng: Number(centerByGrade.longitude)
                      });
                      map?.setZoom(13);
                    }
                  }}
                />
              ))
            )}
          </Box>
        </>
      )}
    </Box>
  ) : (
    <div className="text-center">Loading...</div>
  );
};

export default CentersMap;
