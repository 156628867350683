import React, { useState, useCallback,useEffect,useMemo } from 'react';
import { GoogleMap, MarkerF, InfoWindowF, MarkerClusterer } from '@react-google-maps/api';
import { useGoogleMaps } from '../../contexts/GoogleMapsContext';
import {
  Box,
  Typography,
  Button,
  Paper,
  IconButton,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Slider,
  Menu,
  MenuItem,
  Grid,
  InputLabel,
  Select
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import AddToProposalDialog from '../../Component/Private/AddToProposalDialog/AddToProposalDialog';
import { transformInventoryToOption } from '../../Services/Service';

const containerStyle = {
  width: '100%',
  height: '100%'  // Use parent's height
};

const mapStyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ADD8E6"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];

const mapOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

// Default center (Bangalore)
const defaultinventory = {
  lat: 12.9716,
  lng: 77.5946
};

const circleIconStyle = {
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  backgroundColor: '#ffffff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px solid #FF0000'
};



const InventoryMap = ({ inventories, operatorsData }) => {

  const { isLoaded } = useGoogleMaps();

  // Map state
  const [map, setMap] = useState(null);
  const [selectedinventory, setSelectedinventory] = useState(null);
  const [mapinventory, setMapinventory] = useState(defaultinventory);
  const [showFilters, setShowFilters] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [filteredinventory, setFilteredinventory] = useState([]);
  console.log(filteredinventory, 'filteredinventory');
  const navigate = useNavigate();

  // Filter states
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedOperators, setSelectedOperators] = useState([]);
  const [selectedBuildingTypes, setSelectedBuildingTypes] = useState([]);
  const [selectedBuildingGrades, setSelectedBuildingGrades] = useState([]);
  const [selectedMicromarkets, setSelectedMicromarkets] = useState([]);
  const [sizeRange, setSizeRange] = useState([1, 500]);
  const [priceRange, setPriceRange] = useState([4000, 40000]);
  const [anchorEl, setAnchorEl] = useState(null);

 
  console.log('All operatorsData:', operatorsData);

  // Function to get operator icons for an inventory ID
  const getOperatorIcons = useCallback((inventoryId) => {
    const inventory = inventories.find(inv => inv.id === inventoryId);
    if (!inventory) return null;
    
    const operatorData = operatorsData.find(op => op.name === inventory.operator);
    return operatorData?.icons || null;
  }, [inventories, operatorsData]);

  // State for summary card visibility
  const [visibleSummaries, setVisibleSummaries] = useState({
    cities: true,
    operator: true,
    inventoryType: true
  });

  // Settings panel visibility
  const [showSettings, setShowSettings] = useState(false);

  // Get unique values for filters
  const cities = [...new Set(inventories?.map(center => center.city))].sort();
  const operators = [...new Set(inventories?.map(center => center.operator))].sort();
  const buildingTypes = [...new Set(inventories?.map(center => center.inventoryType))].sort();
  const micromarkets = [...new Set(inventories?.map(center => center.micromarket))].sort();
  const maxSize = 500;
  console.log(cities, 'cities');

  const formatPrice = (price) =>
    price !== undefined && price !== null
      ? new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
      }).format(price).replace("₹", "").trim()
      : "";

  // Calculate city-wise counts
  const cityCounts = inventories.reduce((acc, inventories) => {
    acc[inventories.city] = (acc[inventories.city] || 0) + 1;
    return acc;
  }, {});

  console.log(cityCounts, 'cityCounts');

  const operatorCounts = inventories.reduce((acc, inventories) => {
    if (inventories.operator) {
      acc[inventories.operator] = (acc[inventories.operator] || 0) + 1;
    }
    return acc;
  }, {});

  // Calculate counts from filtered centers
  const calculateCounts = (inventories) => {
    return {
      cities: inventories.reduce((acc, inventory) => {
        if (inventory?.city) {
          acc[inventory.city] = (acc[inventory.city] || 0) + 1;
        }
        return acc;
      }, {}),
      operator: inventories.reduce((acc, inventory) => {
        if (inventory?.operator) {
          acc[inventory.operator] = (acc[inventory.operator] || 0) + 1;
        }
        return acc;
      }, {}),
      buildingGrades: inventories.reduce((acc, inventory) => {
        if (inventory.inventoryType) {
          acc[inventory.inventoryType] = (acc[inventory.inventoryType] || 0) + 1;
        }
        return acc;
      }, {})
    };
  };

  const totalCounts = calculateCounts(inventories);

  // Filtering logic
  const filteredCounts = calculateCounts(
    inventories.filter(inventory => {
      const centerSize = Number(inventory.size) || 0;
      const centerPrice = Number(inventory.listPrice) || 0;
      const matchesCity = selectedCities.length === 0 || selectedCities.includes(inventory.city);
      const matchesOperator = selectedOperators.length === 0 || selectedOperators.includes(inventory.inventoryName);
      const matchesBuildingType = selectedBuildingTypes.length === 0 || selectedBuildingTypes.includes(inventory.inventoryType);
      const matchesMicromarket = selectedMicromarkets.length === 0 || selectedMicromarkets.includes(inventory.micromarket);
      const matchesSize = centerSize >= sizeRange[0] && centerSize <= sizeRange[1];
      const matchesPrice = centerPrice >= priceRange[0] && centerPrice <= priceRange[1];
      return matchesCity &&
        matchesOperator &&
        matchesBuildingType &&
        matchesMicromarket &&
        matchesSize && matchesPrice;
    })
  );
  console.log(filteredCounts, 'filteredCounts');

  // Filter handlers
  const handleCityToggle = (city) => {
    setSelectedCities(prev =>
      prev.includes(city) ? prev.filter(c => c !== city) : [...prev, city]
    );
  };

  const handleOperatorToggle = (operator) => {
    setSelectedOperators(prev =>
      prev.includes(operator) ? prev.filter(o => o !== operator) : [...prev, operator]
    );
  };

  const handleBuildingTypeToggle = (type) => {
    setSelectedBuildingTypes(prev =>
      prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
    );
  };

  const handleBuildingGradeToggle = (grade) => {
    setSelectedBuildingGrades(prev =>
      prev.includes(grade) ? prev.filter(g => g !== grade) : [...prev, grade]
    );
  };

  const handleMicromarketToggle = (micromarket) => {
    setSelectedMicromarkets(prev =>
      prev.includes(micromarket) ? prev.filter(m => m !== micromarket) : [...prev, micromarket]
    );
  };

  const handleSizeRangeChange = (event, newValue) => {
    setSizeRange(newValue);
  };

  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleClearFilters = () => {
    setSelectedCities([]);
    setSelectedOperators([]);
    setSelectedBuildingTypes([]);
    setSelectedBuildingGrades([]);
    setSelectedMicromarkets([]);
    setSizeRange([1, 500]);
    setPriceRange([4000, 40000]);
  };

  // Color mapping for operators
  const operatorColors = useCallback(() => {
    const colors = [
      '#1976d2', // Blue
      '#2e7d32', // Green
      '#d32f2f', // Red
      '#ed6c02', // Orange
      '#9c27b0', // Purple
      '#0288d1', // Light Blue
      '#388e3c', // Light Green
      '#f44336', // Light Red
      '#ff9800', // Deep Orange
      '#673ab7'  // Deep Purple
    ];

    const uniqueOperators = [...new Set(inventories.map(center => center.operator))];
    return Object.fromEntries(
      uniqueOperators.map((operator, index) => [
        operator,
        colors[index % colors.length]
      ])
    );
  }, [inventories]);

  // Function to generate circular icon with border
  const createCircularIcon = (iconUrl) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      canvas.width = 20;
      canvas.height = 20;
      const ctx = canvas.getContext('2d');
      
      // Draw white circle background
      ctx.beginPath();
      ctx.arc(10, 10, 9, 0, 2 * Math.PI);
      ctx.fillStyle = '#ffffff';
      ctx.fill();
      ctx.strokeStyle = '#FF0000';
      ctx.lineWidth = 1;
      ctx.stroke();
      
      // Create image element
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = iconUrl;
      
      img.onload = () => {
        ctx.save();
        ctx.beginPath();
        ctx.arc(10, 10, 8, 0, 2 * Math.PI);
        ctx.clip();
        ctx.drawImage(img, 2, 2, 16, 16);
        ctx.restore();
        
        resolve(canvas.toDataURL());
      };
      
      img.onerror = () => {
        resolve(null);
      };
    });
  };

  // State to store marker icons
  const [markerIcons, setMarkerIcons] = useState({});

  useEffect(() => {
    const loadIcons = async () => {
      // Add a check to ensure filteredinventory and operatorsData are defined
      if (!filteredinventory || !operatorsData) {
        console.warn('filteredinventory or operatorsData is undefined');
        return;
      }

      const iconPromises = filteredinventory.map(async (inventory) => {
        // Use optional chaining and provide a fallback
        const operatorData = operatorsData.find(op => 
          op.operatorName === inventory.operator || 
          op.name === inventory.operator
        );
        
        const operatorIcon = operatorData?.icons?.[0]?.url;
        
        if (operatorIcon) {
          const circularIcon = await createCircularIcon(operatorIcon);
          return { 
            [inventory.id]: circularIcon 
          };
        }
        return null;
      });

      const resolvedIcons = await Promise.all(iconPromises);
      const iconMap = resolvedIcons.reduce((acc, item) => {
        if (item) return { ...acc, ...item };
        return acc;
      }, {});

      setMarkerIcons(iconMap);
    };

    loadIcons().catch(error => {
      console.error('Error loading icons:', error);
    });
  }, [filteredinventory, operatorsData]);

  // Filter centers based on selected filters
  useEffect(() => {
    const filteredInventoryTemp = inventories.filter(inventorymap => {
      // Convert listing price to number and handle null/undefined
      const centerSize = Number(inventorymap.size) || 0;
      const centerPrice = Number(inventorymap.listPrice) || 0;
      const matchesCity = selectedCities.length === 0 || selectedCities.includes(inventorymap.city);
      const matchesOperator = selectedOperators.length === 0 || selectedOperators.includes(inventorymap.operator);
      const matchesBuildingType = selectedBuildingTypes.length === 0 || selectedBuildingTypes.includes(inventorymap.inventoryType);
      const matchesMicromarket = selectedMicromarkets.length === 0 || selectedMicromarkets.includes(inventorymap.micromarket);
      const matchesSize = centerSize >= sizeRange[0] && centerSize <= sizeRange[1];
      const matchesPrice = centerPrice >= priceRange[0] && centerPrice <= priceRange[1];

      return matchesCity && matchesOperator && matchesBuildingType &&
        matchesMicromarket && matchesSize && matchesPrice;
    });

    setFilteredinventory(filteredInventoryTemp);

  }, [inventories, selectedCities, selectedOperators, selectedBuildingTypes, selectedBuildingGrades, selectedMicromarkets, sizeRange, priceRange]);

  useEffect(() => {
    if (filteredinventory.length > 0 && map) {
      const bounds = new window.google.maps.LatLngBounds();

      filteredinventory.forEach(inventory => {
        if (inventory.latitude && inventory.longitude) {
          bounds.extend({
            lat: Number(inventory.latitude),
            lng: Number(inventory.longitude)
          });
        }
      });

      map.fitBounds(bounds);
    }
  }, [filteredinventory, map]);

  const onLoad = (map) => {
    if (inventories.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      inventories.forEach(inventory => {
        if (inventory.latitude && inventory.longitude) {
          bounds.extend({
            lat: Number(inventory.latitude),
            lng: Number(inventory.longitude)
          });
        }
      });

      map.fitBounds(bounds);
      console.log('Zoom:', map.getZoom());

      // Add a small zoom out to show all markers with some padding
      // const listener = window.google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
      //   map.setZoom(map.getZoom() - 1);
      // });

      // return () => {
      //   window.google.maps.event.removeListener(listener);
      // };
    }
    setMap(map);
  };

  const onUnmount = (map) => {
    setMap(null);
  };

  const SummaryCard = ({ title, count, totalCount, onClick }) => (
    <Paper
      elevation={3}
      sx={{
        width: '120px',
        height: '80px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 1,
        flexShrink: 0,
        bgcolor: count === totalCount ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.6)',
        backdropFilter: 'blur(5px)',
        transition: 'all 0.2s',
        '&:hover': {
          transform: 'scale(1.05)',
          cursor: 'pointer',
          bgcolor: 'rgba(255, 255, 255, 0.95)',
        }
      }}
      onClick={onClick}
    >
      <Typography variant="subtitle3" sx={{
        fontWeight: 'medium',
        textAlign: 'center',
        color: 'text.primary'
      }}>
        {title}
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h4" sx={{
          fontWeight: 'bold',
          color: 'text.primary'
        }}>
          {count}
        </Typography>

      </Box>
    </Paper>
  );

  const Divider = () => (
    <Box
      sx={{
        borderLeft: '2px solid rgba(255,255,255,0.3)',
        height: 80,
        mx: 2,
        flexShrink: 0
      }}
    />
  );

  const [addToProposalOpen, setAddToProposalOpen] = useState(false);
  const [proposalOption, setProposalOption] = useState(null);

  const handleAddToProposal = async (inventory) => {
    try {
      const option = await transformInventoryToOption(inventory);
      setProposalOption(option);
      setAddToProposalOpen(true);
    } catch (error) {
      console.error('Error in handleAddToProposal:', error);
      // You might want to add a snackbar here for error handling
    }
  };

  const handleAddToProposalClose = () => {
    setAddToProposalOpen(false);
    setProposalOption(null);
  };

  return isLoaded ? (
    <Box sx={{
      width: '100%',
      height: '100%',
      position: 'relative',
      ...(isFullscreen && {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9000,
        bgcolor: 'background.paper'
      })
    }}>
      {/* Filter toggle button */}
      <IconButton
        onClick={() => setShowFilters(!showFilters)}
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          zIndex: 2,
          bgcolor: 'background.paper',
          boxShadow: 2,
          '&:hover': {
            bgcolor: 'background.paper',
          }
        }}
      >
        <FilterAltIcon />
      </IconButton>

      {/* Settings button */}
      <IconButton
        onClick={() => setShowSettings(!showSettings)}
        sx={{
          position: 'absolute',
          top: 10,
          left: 60,
          zIndex: 2,
          bgcolor: 'background.paper',
          boxShadow: 2,
          '&:hover': {
            bgcolor: 'background.paper',
          }
        }}
      >
        <SettingsIcon />
      </IconButton>

      {/* Settings panel */}
      {showSettings && (
        <Paper
          sx={{
            position: 'absolute',
            top: 60,
            left: 60,
            p: 2,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(8px)',
            borderRadius: 1,
            boxShadow: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            zIndex: 2
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="subtitle2">
              Summary Cards
            </Typography>
            <IconButton size="small" onClick={() => setShowSettings(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleSummaries.cities}
                  onChange={(e) => setVisibleSummaries(prev => ({
                    ...prev,
                    cities: e.target.checked
                  }))}
                  size="small"
                />
              }
              label="Cities"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleSummaries.operator}
                  onChange={(e) => setVisibleSummaries(prev => ({
                    ...prev,
                    operator: e.target.checked
                  }))}
                  size="small"
                />
              }
              label="Operators"
            />

          </FormGroup>
        </Paper>
      )}

      {/* Fullscreen toggle button */}
      <IconButton
        onClick={handleFullscreenToggle}
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 2,
          bgcolor: 'background.paper',
          boxShadow: 2,
          '&:hover': {
            bgcolor: 'background.paper',
          }
        }}
      >
        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </IconButton>

      {/* Floating filter panel */}
      {showFilters && (
        <Paper sx={{
          position: 'absolute',
          top: 60,
          left: 10,
          p: 3,
          maxWidth: 800,
          maxHeight: '80vh',
          overflowY: 'auto',
          zIndex: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          backdropFilter: 'blur(8px)',
          '&:before': {
            content: '"Summary"',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backdropFilter: 'blur(8px)',
            zIndex: -1,
          }
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Filters</Typography>
            <Box>
              <Button
                size="small"
                onClick={handleClearFilters}
                sx={{ mr: 1 }}
              >
                Clear All
              </Button>
              <IconButton size="small" onClick={() => setShowFilters(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={12} md={4} sx={{ p: 2 }}>
              <Box sx={{ p: 2, borderRadius: 1 }}>
                <Typography variant="subtitle1" gutterBottom>Micromarkets</Typography>
                <FormGroup>
                  {micromarkets.map(micromarket => (
                    <FormControlLabel
                      key={micromarket}
                      control={
                        <Checkbox
                          checked={selectedMicromarkets.includes(micromarket)}
                          onChange={() => handleMicromarketToggle(micromarket)}
                          size="small"
                        />
                      }
                      label={<Typography variant="body2">{micromarket}</Typography>}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ p: 2 }}>
              <Box sx={{ p: 2, borderRadius: 1 }}>
                <Typography variant="subtitle1" gutterBottom>Space Types</Typography>
                <FormGroup>
                  {buildingTypes.map(type => (
                    <FormControlLabel
                      key={type}
                      control={
                        <Checkbox
                          checked={selectedBuildingTypes.includes(type)}
                          onChange={() => handleBuildingTypeToggle(type)}
                          size="small"
                        />
                      }
                      label={<Typography variant="body2">{type}</Typography>}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ p: 2 }}>
              <Box sx={{ p: 2, borderRadius: 1 }}>
                <Typography variant="subtitle1" gutterBottom>Size Range</Typography>
                <Box sx={{ px: 2, mt: 2 }}>
                  <Slider
                    value={sizeRange}
                    onChange={handleSizeRangeChange}
                    valueLabelDisplay="auto"
                    min={1}
                    max={500}
                    valueLabelFormat={value => `${value}`}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Typography variant="caption">1</Typography>
                    <Typography variant="caption">500</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ p: 2 }}>
              <Box sx={{ p: 2, borderRadius: 1 }}>
                <Typography variant="subtitle1" gutterBottom>Price Range</Typography>
                <Box sx={{ px: 2, mt: 2 }}>
                  <Slider
                    value={priceRange}
                    onChange={handlePriceRangeChange}
                    valueLabelDisplay="auto"
                    min={4000}
                    max={40000}
                    valueLabelFormat={value => `${value}`}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Typography variant="caption">4000</Typography>
                    <Typography variant="caption">40000</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Map */}
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: isFullscreen ? '100vh' : '100%'
        }}
        center={mapinventory}
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={mapOptions}
      >
        {filteredinventory.length > 0 && (
          <MarkerClusterer
            key={`cluster-${filteredinventory.length}-${selectedCities.join()}-${selectedOperators.join()}-${selectedBuildingTypes.join()}-${selectedBuildingGrades.join()}-${selectedMicromarkets.join()}-${sizeRange.join()}`}
            averageCenter
            enableRetinaIcons
            gridSize={60}
            maxZoom={15}
            minimumClusterSize={2}
          >
            {(clusterer) => (
              <>
                {filteredinventory.map((inventory, index) => {
                  const operatorData = operatorsData.find(op => op.operatorName === inventory.operator);
                  const operatorIcon = operatorData?.icons?.[0]?.url;
                  
                
                  
                  if (!inventory.latitude || !inventory.longitude) return null;

                  const markerIcon = markerIcons[inventory.id] ? {
                    url: markerIcons[inventory.id],
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(10, 10)
                  } : {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillColor: '#FF0000',
                    fillOpacity: 1,
                    strokeWeight: 1,
                    strokeColor: '#FFFFFF',
                    scale: 10
                  };
                  
                  return (
                    <MarkerF
                      key={`${inventory.id}-${index}`}
                      position={{
                        lat: Number(inventory.latitude),
                        lng: Number(inventory.longitude)
                      }}
                      onClick={() => setSelectedinventory(inventory)}
                      // icon={markerIcon}
                      label={{
                        text: inventory.operator?.charAt(0) || "X",
                        width: 20,
                        height: 20,
                        color: 'white',
                        fontWeight: 'bold'
                      }}
                      icon={{
                        path: window.google.maps.SymbolPath.CIRCLE,
                        fillColor: operatorColors(inventory.operator),
                        fillOpacity: 1,
                        strokeWeight: 0,
                        scale: 12
                      }}
                      clusterer={clusterer}
                    />
                  );
                })}
              </>
            )}
          </MarkerClusterer>
        )}
        {selectedinventory && (
          <InfoWindowF
            position={{
              lat: Number(selectedinventory.latitude),
              lng: Number(selectedinventory.longitude)
            }}
            onCloseClick={() => setSelectedinventory(null)}
          >
            <Box sx={{ p: 1, maxWidth: 200 }}>
              {selectedinventory.photos?.[0] && (
                <Box
                  component="img"
                  src={selectedinventory.photos[0].url}
                  alt={selectedinventory.inventoryName}
                  sx={{
                    width: '100%',
                    height: 120,
                    objectFit: 'cover',
                    borderRadius: '10px',
                    mb: 1
                  }}
                />
              )}
              <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 1 }}>
                {selectedinventory.inventoryName}
              </Typography>
              <Typography variant="body2" sx={{ mb: 0 }}>
                Operator: {selectedinventory.operator}
              </Typography>
              <Typography variant="body2" sx={{ mb: 0 }}>
                Size: {selectedinventory.size}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                List Price: {formatPrice(selectedinventory.listPrice)}
              </Typography>
              <Button
                variant="contained"
                size="small"
                fullWidth
                onClick={() => handleAddToProposal(selectedinventory)}
                sx={{ mt: 1 }}
              >
                Add to Proposal
              </Button>
            </Box>
          </InfoWindowF>
        )}
      </GoogleMap>

      {/* Summary section */}
      {isFullscreen && (
        <>
          {/* Summary cards */}
          <Box sx={{
            position: 'absolute',
            bottom: 20,
            left: 20,
            display: 'flex',
            gap: 2,
            p: 2,
            overflowX: 'auto',
            maxWidth: 'calc(100% - 120px)',
            '&::-webkit-scrollbar': {
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              bgcolor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              bgcolor: 'rgba(255, 255, 255, 0.5)',
              borderRadius: '4px',
            },
            '&::before': {
              content: '"Summary"',
              position: 'absolute',
              top: -25,
              left: 8,
              color: 'white',
              textShadow: '0 0 4px rgba(0,0,0,0.5)',
              fontWeight: 500
            }
          }}>
            {/* City cards */}
            {visibleSummaries.cities && (
              <>
                {Object.entries(totalCounts.cities).sort().map(([city, totalCount]) => (
                  <SummaryCard
                    key={`city-${city}`}
                    title={city}
                    count={filteredCounts.cities[city] || 0}
                    totalCount={totalCount}
                    onClick={() => {
                      const inventoryInCity = inventories.find(c => c.city === city);
                      if (inventoryInCity) {
                        setMapinventory({
                          lat: Number(inventoryInCity.latitude),
                          lng: Number(inventoryInCity.longitude)
                        });
                        map?.setZoom(13);
                      }
                    }}
                  />
                ))}
                {(visibleSummaries.operator || visibleSummaries.buildingGrades) && <Divider />}
              </>
            )}

            {/* Operator cards */}
            {visibleSummaries.operator && (
              <>
                {Object.entries(totalCounts.operator).sort().map(([operator, totalCount]) => (
                  <SummaryCard
                    key={`operator-${operator}`}
                    title={operator}
                    count={filteredCounts.operator[operator] || 0}
                    totalCount={totalCount}
                    onClick={() => {
                      setSelectedOperators(prev => {
                        const newOperators = new Set(prev);
                        newOperators.clear();
                        newOperators.add(operator);
                        return newOperators;
                      });
                      const inventoryByOperator = inventories.find(c => c.operator === operator);
                      if (inventoryByOperator) {
                        setMapinventory({
                          lat: Number(inventoryByOperator.latitude),
                          lng: Number(inventoryByOperator.longitude)
                        });
                        map?.setZoom(13);
                      }
                    }}
                  />
                ))}
                {visibleSummaries.buildingGrades && <Divider />}
              </>
            )}

            {/* Building Grade cards */}
            {/* {visibleSummaries.buildingGrades && (
              Object.entries(totalCounts.buildingGrades).sort().map(([grade, totalCount]) => (
                <SummaryCard
                  key={`grade-${grade}`}
                  title={grade}
                  count={filteredCounts.buildingGrades[grade] || 0}
                  totalCount={totalCount}
                  onClick={() => {
                    setSelectedBuildingGrades(prev => {
                      const newGrades = new Set(prev);
                      newGrades.clear();
                      newGrades.add(grade);
                      return newGrades;
                    });
                    const inventoryByGrade = inventories.find(c => c.buildingGrade === grade);
                    if (inventoryByGrade) {
                      setMapCenter({
                        lat: Number(inventoryByGrade.latitude),
                        lng: Number(inventoryByGrade.longitude)
                      });
                      map?.setZoom(13);
                    }
                  }}
                />
              ))
            )} */}
          </Box>
        </>
      )}
      <AddToProposalDialog
        open={addToProposalOpen}
        onClose={handleAddToProposalClose}
        option={proposalOption}
      />
    </Box>
  ) : (
    <div className="text-center">Loading...</div>
  );
};

export default InventoryMap;
