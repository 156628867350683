import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase/config";
import ProposalView from "../../../Pages/Public/ProposalView";
import flyloader from "../../../assest/image/Fetching Proposal.gif";
import { getProposalStatus } from "../../../Services/FirebaseUtils";

const ProposalFetcher = () => {
  const [proposal, setProposal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shortlistedCenters, setShortlistedCenters] = useState({});
  const [schedules, setSchedules] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const proposalId = queryParams.get('proposalId');

  useEffect(() => {
    const fetchProposalData = async () => {
      try {
        const authToken = window.localStorage.getItem('authToken');

        if (!authToken) {
          navigate('/?proposalId=' + proposalId);
          return;
        }

        // First check if the proposal is published
        const statusResult = await getProposalStatus(proposalId);
        if (!statusResult.success) {
          setError(statusResult.error);
          setLoading(false);
          return;
        }

        if (!statusResult.isPublished) {
          setError('This proposal is not available for viewing');
          setLoading(false);
          return;
        }

        // Check if proposal exists in Firestore
        const proposalRef = doc(db, 'proposals', proposalId);
        const proposalDoc = await getDoc(proposalRef);

        let proposalData;
        let shouldFetchFromAPI = false;

        if (!proposalDoc.exists()) {
          // Proposal not in database, must fetch from API
          shouldFetchFromAPI = true;
        } else {
          // Check if proposedOptions is empty or missing
          const existingProposalData = proposalDoc.data();
          if (!existingProposalData.proposedOptions || existingProposalData.proposedOptions.length === 0) {
            shouldFetchFromAPI = true;
          } else {
            proposalData = existingProposalData;
          }
        }

        // Fetch from API if needed
        if (shouldFetchFromAPI) {
          const baseUrl = 'https://gofloaters.firebaseapp.com';
          const response = await axios.get(`${baseUrl}/proposal/${proposalId}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`
            }
          });

          if (response.data && response.data.data) {
            proposalData = response.data.data;

            // Store API response in Firestore
            await setDoc(proposalRef, {
              clientName: proposalData.clientName || "Unknown Client",
              requirements: proposalData.requirements || "No requirements specified",
              proposedOptions: proposalData.proposedOptions || [],
              refreshData: !proposalData.proposedOptions || proposalData.proposedOptions.length === 0,
              lastUpdated: new Date().toISOString(),
              status: 'Published' // Ensure status is set to Published
            }, { merge: true });
          } else {
            throw new Error('Invalid response format from server');
          }
        }

        // Ensure proposalData is set
        if (!proposalData) {
          throw new Error('No proposal data available');
        }

        setProposal(proposalData);
      } catch (error) {
        console.error('Error fetching proposal:', error);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            // Authentication error - redirect to login
            navigate('/?proposalId=' + proposalId);
            return;
          } else if (error.response.status === 500) {
            setError('Server error. Please try again later.');
          } else if (error.response.status === 404) {
            setError('Proposal not found. Please check the proposal ID.');
          } else {
            setError(error.response.data?.message || 'Error fetching proposal data');
          }
        } else {
          setError('Error connecting to server. Please check your internet connection.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProposalData();

    // Set up real-time listeners for shortlisted centers and schedules
    const shortlistRef = doc(db, 'shortlisted', proposalId);
    const shortlistUnsubscribe = onSnapshot(shortlistRef, (doc) => {
      if (doc.exists()) {
        setShortlistedCenters(doc.data());
      }
    });

    const scheduleRef = doc(db, 'schedules', proposalId);
    const scheduleUnsubscribe = onSnapshot(scheduleRef, (doc) => {
      if (doc.exists()) {
        setSchedules(doc.data().schedules || []);
      }
    });

    // Cleanup function
    return () => {
      shortlistUnsubscribe();
      scheduleUnsubscribe();
    };
  }, [proposalId, navigate]);

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#fff'
      }}>
        <img src={flyloader} alt="Loading..." style={{ maxWidth: '100%', height: 'auto' }} />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: '20px',
        textAlign: 'center',
        color: '#dc2626'
      }}>
        <h2>Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <ProposalView
      proposal={proposal}
      proposalId={proposalId}
      shortlistedCenters={shortlistedCenters}
      schedules={schedules}
    />
  );
};

export default ProposalFetcher;
