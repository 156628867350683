import React, { createContext, useContext, useMemo } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

const GoogleMapsContext = createContext(null);

// Define libraries array outside component to prevent recreation on each render
const libraries = ['places', 'maps'];

export const GoogleMapsProvider = ({ children }) => {
    // Memoize the configuration object
    const options = useMemo(() => ({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyBFzQyh0__KlIMKsmGqMIsnsolOeXTYoUs',
        libraries
    }), []); // Empty dependency array since all values are static

    const { isLoaded } = useJsApiLoader(options);

    return (
        <GoogleMapsContext.Provider value={{ isLoaded }}>
            {children}
        </GoogleMapsContext.Provider>
    );
};

export const useGoogleMaps = () => {
    const context = useContext(GoogleMapsContext);
    if (context === null) {
        throw new Error('useGoogleMaps must be used within a GoogleMapsProvider');
    }
    return context;
};
