import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Select,
  MenuItem,
  InputBase,
  useTheme,
  useMediaQuery,
  Card,
  Toolbar,
  InputAdornment,
  TextField,
  TablePagination,
  Menu,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Stepper,
  Step,
  StepLabel,
  Tooltip,
  FormControl,
  InputLabel
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { 
  createProposal, 
  publishProposal, 
  unpublishProposal, 
  deleteProposal,
  updateProposalStatus 
} from '../../../Services/FirebaseUtils';

const Proposals = () => {
  const dispatch = useDispatch();
  const { items: proposals, loading } = useSelector((state) => state.proposals);
  const userData = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProposalId, setSelectedProposalId] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [publicUrlDialog, setPublicUrlDialog] = useState({ open: false, url: '' });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [newStatus, setNewStatus] = useState('');

  const theme = useTheme();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleMenuClick = (event, proposalId) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedProposalId(proposalId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = (proposalId) => {
    navigate(`/private/dashboard/proposals/edit/${proposalId}`, { state: { mode: 'edit' } });
    handleMenuClose();
  };

  const handleViewClick = (proposalId) => {
    navigate(`/private/dashboard/proposals/edit/${proposalId}`, { state: { mode: 'view' } });
    handleMenuClose();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Draft':
        return {
          color: theme.palette.grey[600],
          backgroundColor: theme.palette.grey[100],
        };
      case 'Approved':
        return {
          color: theme.palette.warning.dark,
          backgroundColor: theme.palette.warning.light,
        };
      case 'Published':
        return {
          color: theme.palette.success.dark,
          backgroundColor: theme.palette.success.light,
        };
      default:
        return {
          color: theme.palette.grey[600],
          backgroundColor: theme.palette.grey[100],
        };
    }
  };

  const getShortlistCount = (proposal) => {
    if (!proposal.shortlist) return 0;
    return proposal.shortlist.length;
  };
  const getQuestionsCount = (proposal) => {
    if (!proposal.feedback) return '0';
    const feedbackArray = Object.values(proposal.feedback);
    let answeredQuestionsCount = 0;
    let questionCount = 0;
    feedbackArray.map((feedback) => {
      if (!feedback.feedbackItem) return;
      const feedbackItemArray = Object.values(feedback.feedbackItem);
      feedbackItemArray.map((item) => {
        questionCount += 1;
        if (item.answer && item.answer !== '') {
          answeredQuestionsCount += 1;
        }
      });
      return null;
    });
    return `${answeredQuestionsCount}/${questionCount}`;
  };

  const getNotesCount = (proposal) => {
    if (!proposal.notes) return 0;
    return Object.keys(proposal.notes).length;
  };

  const getViewersCount = (proposal) => {
    return proposal.viewers? Object.keys(proposal.viewers).length : 0;
  };

  const getViewersList = (proposal) => {
    if (!proposal.viewers || proposal.viewers.length === 0) {
      return "No viewers yet";
    }
    let viewersList = Object.values(proposal.viewers);
    console.log("viewersList", viewersList);
    return viewersList.join('\n');
  };

  const getOptionsCount = (proposal) => {
    if (!proposal.proposedOptions) return 0;
    let count = Object.keys(proposal.proposedOptions).length ;
    return count;
  };

  const handleCreateNewProposal = async () => {
    const result = await createProposal(userData.email);
    if (result.success) {
      navigate(`/private/dashboard/proposals/edit/${result.proposalId}`, { state: { mode: 'edit' } });
    }
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
    handleMenuClose();
  };

  const handleDeleteConfirm = async () => {
    try {
      const result = await deleteProposal(selectedProposalId);
      if (result.success) {
        setDeleteDialogOpen(false);
      } else {
        console.error('Failed to delete proposal:', result.error);
      }
    } catch (error) {
      console.error('Error deleting proposal:', error);
    }
  };

  const handlePublishClick = async () => {
    try {
      const result = await publishProposal(selectedProposalId);
      if (result.success) {
        setPublicUrlDialog({ open: true, url: result.publicUrl });
      } else {
        console.error('Failed to publish proposal:', result.error);
        setSnackbar({
          open: true,
          message: 'Failed to publish proposal',
          severity: 'error'
        });
      }
      handleMenuClose();
    } catch (error) {
      console.error('Error publishing proposal:', error);
      setSnackbar({
        open: true,
        message: 'Error publishing proposal',
        severity: 'error'
      });
    }
  };

  const handleUnpublishClick = async () => {
    try {
      const result = await unpublishProposal(selectedProposalId);
      if (!result.success) {
        console.error('Failed to unpublish proposal:', result.error);
      }
      handleMenuClose();
    } catch (error) {
      console.error('Error unpublishing proposal:', error);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const handleCopyUrl = (event, url = null) => {
    if (event) {
      event.stopPropagation();
    }
    const urlToCopy = url || publicUrlDialog.url;
    navigator.clipboard.writeText(urlToCopy).then(() => {
      setSnackbar({
        open: true,
        message: 'URL copied to clipboard',
        severity: 'success'
      });
    }).catch(() => {
      setSnackbar({
        open: true,
        message: 'Failed to copy URL',
        severity: 'error'
      });
    });
  };

  const handleClosePublicUrl = () => {
    setPublicUrlDialog({ open: false, url: '' });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const filteredProposals = proposals.filter(proposal =>
    proposal.clientName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const PROPOSAL_STAGES = [
    'Draft',
    'Published',
    'Shortlisting',
    'Visits',
    'Negotiation',
    'Closed Won',
    'Closed Lost'
  ];

  const getStageColor = (currentStage, stage) => {
    const currentIndex = PROPOSAL_STAGES.indexOf(currentStage);
    const stageIndex = PROPOSAL_STAGES.indexOf(stage);
    
    if (currentStage === 'Closed Lost') {
      return '#ff4444';
    }
    if (currentStage === 'Closed Won') {
      return '#00C853';
    }
    if (stageIndex === currentIndex) {
      return '#1976d2';
    }
    if (stageIndex < currentIndex) {
      return '#4caf50';
    }
    return '#e0e0e0';
  };

  const getProposalStage = (proposal) => {
    if (!proposal) return 'Draft';
    
    if (proposal.status === 'Closed Won') return 'Closed Won';
    if (proposal.status === 'Closed Lost') return 'Closed Lost';
    
    if (proposal.status === 'Visits' || (proposal.visitSchedule && Object.keys(proposal.visitSchedule).length > 0)) {
      return 'Visits';
    }
    
    if (proposal.status === 'Shortlisting' || (proposal.shortlist && Object.keys(proposal.shortlist).length > 0)) {
      return 'Shortlisting';
    }
    
    if (proposal.status === 'Published') {
      return 'Published';
    }
    
    return 'Draft';
  };

  const ProposalStatus = ({ proposal }) => {
    const currentStatus = getProposalStage(proposal);
    
    return (
      <Box sx={{ width: '100%', p: 1 }}>
        <Stepper activeStep={PROPOSAL_STAGES.indexOf(currentStatus)} alternativeLabel>
          {PROPOSAL_STAGES.map((stage, index) => {
            const isActive = stage === currentStatus;
            const isCompleted = PROPOSAL_STAGES.indexOf(currentStatus) >= index;
            
            return (
              <Step key={stage} completed={isCompleted}>
                <StepLabel
                  sx={{
                    '& .MuiStepLabel-iconContainer': {
                      p: 0,
                      '& .MuiStepIcon-root': {
                        color: getStageColor(currentStatus, stage),
                        width: '20px',
                        height: '20px'
                      }
                    },
                    '& .MuiStepLabel-label': {
                      fontSize: '0.75rem',
                      color: isActive ? getStageColor(currentStatus, stage) : 'text.secondary'
                    }
                  }}
                >
                  {stage}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    );
  };

  const handleStatusChange = async () => {
    try {
      const result = await updateProposalStatus(selectedProposalId, newStatus);
      if (!result.success) {
        throw new Error(result.error);
      }

      setOpenStatusDialog(false);
      setNewStatus('');
      handleMenuClose();

      setSnackbar({
        open: true,
        message: 'Status updated successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error updating status:', error);
      setSnackbar({
        open: true,
        message: 'Error updating status: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleStatusDialogOpen = () => {
    const selectedProposal = proposals.find(p => p.id === selectedProposalId);
    setNewStatus(selectedProposal?.status || 'Draft');
    setOpenStatusDialog(true);
  };

  return (
    <Card sx={{ borderRadius: 1 }}>
      <Box sx={{ px: 2, py: 1 }}>
        {/* Toolbar */}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
          minHeight: '48px'
        }}>
          {/* Search */}
          <TextField
            placeholder="Search proposal..."
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{
              width: 300,
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#fff',
                height: '36px',
                '& fieldset': {
                  borderColor: '#E5E7EB',
                },
                '&:hover fieldset': {
                  borderColor: '#D1D5DB',
                },
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: '#9CA3AF', fontSize: '20px' }} />
                </InputAdornment>
              ),
            }}
          />

          {/* Right side: Action buttons */}
          {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon sx={{ fontSize: '20px' }} />}
              onClick={handleCreateNewProposal}
              sx={{
                textTransform: 'none',
                px: 2,
                py: 0.75,
                height: '36px',
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                }
              }}
            >
              New Proposal
            </Button>
          </Box> */}
        </Box>

        {/* Separator Line */}
        <Box sx={{
          height: '1px',
          backgroundColor: '#e0e0e0',
          width: '100%',
          mx: -2,
          width: 'calc(100% + 32px)',
          mb: 2
        }} />

        {/* Table */}
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: (theme) => `${theme.palette.primary.main}08` }}>
                <TableCell sx={{
                  fontWeight: 500,
                  color: '#6B7280',
                  borderBottom: '1px solid #E5E7EB',
                  py: 1.5
                }}>
                  Client Name
                </TableCell>
                {/* <TableCell sx={{
                  fontWeight: 500,
                  color: '#6B7280',
                  borderBottom: '1px solid #E5E7EB',
                  py: 1.5
                }}>
                  City
                </TableCell> */}
                <TableCell sx={{
                  fontWeight: 500,
                  color: '#6B7280',
                  borderBottom: '1px solid #E5E7EB',
                  py: 1.5
                }}>
                  Options
                </TableCell>
                <TableCell sx={{
                  fontWeight: 500,
                  color: '#6B7280',
                  borderBottom: '1px solid #E5E7EB',
                  py: 1.5
                }}>
                  Status
                </TableCell>
                <TableCell sx={{
                  fontWeight: 500,
                  color: '#6B7280',
                  borderBottom: '1px solid #E5E7EB',
                  py: 1.5
                }}>
                  Engagement
                </TableCell>
                <TableCell sx={{
                  fontWeight: 500,
                  color: '#6B7280',
                  borderBottom: '1px solid #E5E7EB',
                  py: 1.5
                }}>
                  Created Date
                </TableCell>
                <TableCell sx={{
                  fontWeight: 500,
                  color: '#6B7280',
                  borderBottom: '1px solid #E5E7EB',
                  py: 1.5
                }}>
                  Last Modified
                </TableCell>
                <TableCell sx={{
                  fontWeight: 500,
                  color: '#6B7280',
                  borderBottom: '1px solid #E5E7EB',
                  py: 1.5
                }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProposals
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((proposal) => (
                  <TableRow
                    key={proposal.id}
                    hover
                    sx={{
                      '&:hover': {
                        backgroundColor: '#F5F3FF',
                        cursor: 'pointer'
                      },
                      '& .MuiTableCell-root': {
                        py: 1.5
                      }
                    }}
                  >
                    <TableCell sx={{
                      borderBottom: '1px solid #E5E7EB',
                      color: '#111827',
                      py: 1.5
                    }}>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {proposal.clientName}
                      </Typography>
                    </TableCell>
                    {/* <TableCell sx={{
                      borderBottom: '1px solid #E5E7EB',
                      color: '#111827',
                      py: 1.5
                    }}>
                      <Typography variant="body1">
                        {proposal.city}
                      </Typography>
                    </TableCell> */}
                    <TableCell sx={{
                      borderBottom: '1px solid #E5E7EB',
                      color: '#111827',
                      py: 1.5
                    }}>
                      <Typography variant="body1">
                        {getOptionsCount(proposal)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{
                      borderBottom: '1px solid #E5E7EB',
                      color: '#111827',
                      py: 1.5
                    }}>
                      <ProposalStatus proposal={proposal} />
                    </TableCell>
                    <TableCell sx={{
                      borderBottom: '1px solid #E5E7EB',
                      color: '#111827',
                      py: 1.5
                    }}>
                      <Box>
                        <Tooltip 
                          title={getViewersList(proposal)} 
                          placement="top-start"
                          sx={{ 
                            whiteSpace: 'pre-line',
                            '& .MuiTooltip-tooltip': {
                              backgroundColor: 'white',
                              color: 'black',
                              boxShadow: '0px 2px 8px rgba(0,0,0,0.15)',
                              padding: '8px',
                              fontSize: '14px'
                            }
                          }}
                        >
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5, cursor: 'pointer' }}>
                            <Box component="span" fontWeight="medium">Viewers:</Box> {getViewersCount(proposal)}
                          </Typography>
                        </Tooltip>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <Box component="span" fontWeight="medium">Shortlisted:</Box> {getShortlistCount(proposal)}
                        </Typography>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <Box component="span" fontWeight="medium">Questions:</Box> {getQuestionsCount(proposal)}
                        </Typography>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <Box component="span" fontWeight="medium">Notes :</Box> {getNotesCount(proposal)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{
                      borderBottom: '1px solid #E5E7EB',
                      color: '#111827',
                      py: 1.5
                    }}>
                      {proposal.createdAt ? new Date(proposal.createdAt.split(' at ')[0]).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      }) : ''}
                    </TableCell>
                    <TableCell sx={{
                      borderBottom: '1px solid #E5E7EB',
                      color: '#111827',
                      py: 1.5
                    }}>
                      {proposal.lastModified ? new Date(proposal.lastModified.split(' at ')[0]).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      }) : ''}
                    </TableCell>
                    <TableCell sx={{
                      borderBottom: '1px solid #E5E7EB',
                      color: '#111827',
                      py: 1.5
                    }}>
                      <IconButton
                        size="small"
                        onClick={(event) => handleMenuClick(event, proposal.id)}
                      >
                        <MoreVertIcon sx={{ fontSize: '1.2rem', color: '#6B7280' }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <TablePagination
            component="div"
            count={filteredProposals.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              borderTop: '1px solid #e0e0e0',
              display: 'flex',
              alignItems: 'center',
              '.MuiTablePagination-toolbar': {
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              },
              '.MuiTablePagination-displayedRows': {
                margin: 0,
              },
              '.MuiTablePagination-selectLabel': {
                margin: 0,
              },
              '.MuiTablePagination-select': {
                borderRadius: 1,
                border: '1px solid #e0e0e0',
                marginLeft: 1,
                marginRight: 2,
                paddingTop: 0.5,
                paddingBottom: 0.5,
              },
              '.MuiTablePagination-actions': {
                marginLeft: 2,
                display: 'flex',
                gap: 1,
              }
            }}
          />
        </Box>

        {/* Action Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
              minWidth: '120px'
            }
          }}
        >
          <MenuItem onClick={() => handleViewClick(selectedProposalId)}>View</MenuItem>
          <MenuItem onClick={() => handleEditClick(selectedProposalId)}>Edit Proposal</MenuItem>
          <MenuItem onClick={handleStatusDialogOpen}>Change Status</MenuItem>
          {proposals.find(p => p.id === selectedProposalId)?.status === 'Published' ? (
            <MenuItem onClick={handleUnpublishClick}>Unpublish</MenuItem>
          ) : (
            (() => {
              const selectedProposal = proposals.find(p => p.id === selectedProposalId);
              return selectedProposal && getOptionsCount(selectedProposal) > 0 ? (
                <MenuItem onClick={handlePublishClick}>Publish</MenuItem>
              ) : null;
            })()
          )}
          <MenuItem 
            onClick={handleDeleteClick} 
            sx={{ color: 'dark' }}
            disabled={proposals.find(p => p.id === selectedProposalId)?.status === 'Published'}
          >
            {proposals.find(p => p.id === selectedProposalId)?.status === 'Published' ? 'Cannot Delete Published Proposal' : 'Delete'}
          </MenuItem>
        </Menu>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteDialogOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="delete-dialog-title">Delete Proposal</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this proposal ?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel}>Cancel</Button>
            <Button onClick={handleDeleteConfirm} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Public URL Dialog */}
        <Dialog
          open={publicUrlDialog.open}
          onClose={handleClosePublicUrl}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Proposal Published Successfully</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                fullWidth
                value={publicUrlDialog.url}
                InputProps={{
                  readOnly: true,
                  sx: { pr: 1 }
                }}
              />
              <IconButton
                onClick={(e) => handleCopyUrl(e)}
                color="primary"
                sx={{
                  backgroundColor: (theme) => `${theme.palette.primary.main}08`,
                  '&:hover': {
                    backgroundColor: (theme) => `${theme.palette.primary.main}12`,
                  }
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePublicUrl}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* Change Status Dialog */}
        <Dialog
          open={openStatusDialog}
          onClose={() => setOpenStatusDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Change Status</DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status-select"
                value={newStatus}
                label="Status"
                onChange={(e) => setNewStatus(e.target.value)}
              >
                {PROPOSAL_STAGES.map((stage) => (
                  <MenuItem key={stage} value={stage}>{stage}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenStatusDialog(false)}>Cancel</Button>
            <Button 
              onClick={handleStatusChange}
              variant="contained"
              disabled={!newStatus || newStatus === proposals.find(p => p.id === selectedProposalId)?.status}
            >
              Update Status
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Card>
  );
};

export default Proposals;