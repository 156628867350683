import React, { useEffect } from "react";
import Centerdetails from "../../../Pages/Public/Centerdetails";

const Centerdetailscard = ({proposal, proposalId, shortlistedCenters, isEditable}) => {

  return (
    <div className="container">
      {proposal?.proposedOptions?.map((option, index) => {
        return (
          <div 
            key={index}
            id={`center-details-${option.centerName}`}
          >
            <Centerdetails
              proposalId={proposalId}
              proposal={proposal}
              option={option}
              id={option.id}
              centerName={option.centerName}
              inventoryDescription={option.inventoryDescription}
              billableSeats={option.billableSeats}
              pricePerSeat={option.pricePerSeat}
              totalRentPerMonth={option.totalRentPerMont}  
              amenities={option.amenities}
              termsAndConditions={option.terms}
              virtualTours={option.virtualTours}
              location={option.location}
              layouts={option.layouts}
              photos={option.photos}
              availableFrom={option.availabilityDate}
              parkingCharges={option.parkingCharges}
              securityDeposit={option.securityDeposit}
              bikeParkingCost={option.bikeParkingCost}
              carParkingCost={option.carParkingCost}
              shortlistedCenters={shortlistedCenters}
              isEditable={isEditable}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Centerdetailscard;
