import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  InputAdornment,
  Card,
  Button,
  TablePagination,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  IconButton,
  Grid,
  Divider,
  Menu,
  ListItemIcon,
  ListItemText,
  CircularProgress
} from "@mui/material";
import { fetchOperators, updateOperator, deleteOperator, addOperator, fetchInventoriesByOperator, fetchCentersByOperator, addInventory } from "../../../Services/FirebaseUtils";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';
import { useNavigate } from "react-router-dom";
import ImportInventoryModal from '../../../Component/Private/ImportInventoryModal';
import OperatorForm from "../../../Components/Private/OperatorForm";
import { useMeta } from '../../../hooks/useMeta';
import MapIcon from '@mui/icons-material/Map';
import { useSelector } from 'react-redux';
import { hasActionPermission } from '../../../utils/rolePermissions';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  maxHeight: '90vh',
  overflow: 'auto'
};

const Operators = () => {
  const [operators, setOperators] = useState([]);
  const [operatorTypes, setOperatorTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("operatorName");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [openOperatorModal, setOpenOperatorModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [actionRow, setActionRow] = useState(null);
  const [importData, setImportData] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isLoading, setIsLoading] = useState(false);

  const { cities, isLoading: citiesLoading } = useMeta();
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.data);
  const userRole = user?.role || '';

  useEffect(() => {
    const loadOperators = async () => {
      setIsLoading(true);
      try {
        // If user role starts with 'operator-', fetch only their operator data
        const operatorId = userRole.startsWith('operator-') ? user?.operatorId : null;
        const data = await fetchOperators(operatorId);
        setOperators(data.operators);
        setOperatorTypes(data.operatorTypes);
      } catch (error) {
        console.error('Error loading operators:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadOperators();
  }, [userRole, user?.operatorId]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = (operator) => {
    setSelectedOperator(operator);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOperator(null);
  };

  const handleImportClick = async (operator) => {
    try {
      setSelectedOperator(operator);
      
      // Fetch both inventories and centers
      const [inventoriesResult, centersResult] = await Promise.all([
        fetchInventoriesByOperator(operator.id),
        fetchCentersByOperator(operator.id)
      ]);
      
      if (!inventoriesResult.success) {
        setSnackbar({
          open: true,
          message: 'Error fetching inventories: ' + inventoriesResult.error,
          severity: 'error'
        });
        return;
      }

      if (!centersResult.success) {
        setSnackbar({
          open: true,
          message: 'Error fetching centers: ' + centersResult.error,
          severity: 'error'
        });
        return;
      }

      setIsImportModalOpen(true);
      setImportData({
        operator: operator,
        existingInventories: inventoriesResult.inventories,
        centers: centersResult.centers
      });
    } catch (error) {
      console.error('Error handling import:', error);
      setSnackbar({
        open: true,
        message: 'Error preparing import: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleImportClose = () => {
    setIsImportModalOpen(false);
    setSelectedOperator(null);
    setOpenModal(false);
  };

  const handleImport = async (importData) => {
    try {
      // Implement inventory import logic
      const importedInventories = await Promise.all(
        importData.data.map(async (inventoryItem) => {
          // Use addInventory from FirebaseUtils
          return await addInventory({
            ...inventoryItem,
            operatorId: selectedOperator.id,
            operatorName: selectedOperator.operatorName
          });
        })
      );

      // Close modal and show success message
      setIsImportModalOpen(false);
      setSnackbar({
        open: true,
        message: `Successfully imported ${importedInventories.length} inventories`,
        severity: 'success'
      });

      // Refresh inventories
      await fetchInventoriesByOperator(selectedOperator.id);
    } catch (error) {
      console.error('Error importing inventories:', error);
      setSnackbar({
        open: true,
        message: 'Failed to import inventories',
        severity: 'error'
      });
    }
  };

  const handleEditItem = async (inventoryData) => {
    try {
      // Use addInventory from FirebaseUtils to add the new inventory
      const newInventory = await addInventory({
        ...inventoryData,
        operatorId: selectedOperator.id,
        operatorName: selectedOperator.operatorName
      });

      // Show success message
      setSnackbar({
        open: true,
        message: 'Inventory added successfully',
        severity: 'success'
      });

      // Refresh inventories
      await fetchInventoriesByOperator(selectedOperator.id);

      return newInventory;
    } catch (error) {
      console.error('Error adding inventory:', error);
      setSnackbar({
        open: true,
        message: 'Failed to add inventory',
        severity: 'error'
      });
      throw error;
    }
  };

  const handleActionsClick = (event, row) => {
    event.stopPropagation();
    setActionRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchorEl(null);
    setActionRow(null);
  };

  const handleView = () => {
    setSelectedOperator(actionRow);
    setOpenModal(true);
    handleActionsClose();
  };

  const handleEdit = () => {
    setSelectedOperator(actionRow);
    setEditMode(true);
    setOpenOperatorModal(true);
    handleActionsClose();
  };

  const handlePublish = async () => {
    try {
      const result = await updateOperator(actionRow.id, { ...actionRow, published: true });
      if (result.success) {
        // Refresh operators list
        const updatedOperators = await fetchOperators();
        setOperators(updatedOperators.operators);
      } else {
        console.error("Error publishing operator:", result.error);
      }
    } catch (error) {
      console.error("Error publishing operator:", error);
    }
    handleActionsClose();
  };

  const handleDelete = async () => {
    try {
      const result = await deleteOperator(actionRow.id);
      if (result.success) {
        // Refresh operators list
        const updatedOperators = await fetchOperators();
        setOperators(updatedOperators.operators);
      } else {
        console.error("Error deleting operator:", result.error);
      }
    } catch (error) {
      console.error("Error deleting operator:", error);
    }
    handleActionsClose();
  };

  const handleOperatorSubmit = async (formData) => {
    try {
      let result;
      if (editMode) {
        result = await updateOperator(selectedOperator.id, formData);
      } else {
        result = await addOperator(formData);
      }

      if (result.success) {
        // Refresh operators list
        const updatedOperators = await fetchOperators();
        setOperators(updatedOperators.operators);
        setOpenOperatorModal(false);
        setEditMode(false);
        setSelectedOperator(null);
      } else {
        console.error("Error submitting operator:", result.error);
      }
    } catch (error) {
      console.error("Error submitting operator:", error);
    }
  };

  const filteredOperators = operators.filter((operator) =>
    operator.operatorName?.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedType === "all" || operator.operatorType === selectedType)
  );

  const sortedOperators = filteredOperators.sort((a, b) => {
    const isAsc = order === "asc";
    if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1;
    return 0;
  });

  const paginatedOperators = sortedOperators.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const renderActions = ({ row }) => {
    return (
      <>
        <IconButton
          size="small"
          onClick={(e) => handleActionsClick(e, row)}
          sx={{ color: "#6B7280" }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && actionRow?.id === row.id}
          onClose={handleActionsClose}
          PaperProps={{
            sx: {
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
              border: "1px solid #E5E7EB",
              minWidth: "120px",
            },
          }}
        >
          <MenuItem onClick={handleView}>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>View</ListItemText>
          </MenuItem>

          {hasActionPermission('operators', 'view_centers', userRole) && (
            <MenuItem onClick={() => navigate(`/private/dashboard/centers?operator=${encodeURIComponent(row.operatorName)}`)}>
              <ListItemIcon>
                <MapIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>View Centers</ListItemText>
            </MenuItem>
          )}

          {hasActionPermission('operators', 'edit', userRole) && (
            <MenuItem onClick={handleEdit}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          )}

          {hasActionPermission('operators', 'import', userRole) && (
            <MenuItem onClick={() => handleImportClick(row)}>
              <ListItemIcon>
                <PublishIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Import</ListItemText>
            </MenuItem>
          )}

          {hasActionPermission('operators', 'delete', userRole) && (
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </>
    );
  };

  return (
    <Card sx={{ borderRadius: 1 }}>
      <Box sx={{ px: 2, py: 1 }}>
        {/* Toolbar */}
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          minHeight: "48px"
        }}>
          {/* Search */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <TextField
              placeholder="Search operator..."
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              sx={{
                width: 300,
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#fff",
                  height: "36px",
                  "& fieldset": {
                    borderColor: "#E5E7EB",
                  },
                  "&:hover fieldset": {
                    borderColor: "#D1D5DB",
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#9CA3AF", fontSize: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />

            <FormControl
              size="small"
              sx={{
                minWidth: 200,
                "& .MuiOutlinedInput-root": {
                  height: "36px",
                  "& fieldset": {
                    borderColor: "#E5E7EB",
                  },
                  "&:hover fieldset": {
                    borderColor: "#D1D5DB",
                  }
                }
              }}
            >
              <InputLabel id="type-filter-label" sx={{ color: "#6B7280" }}>Filter by Type</InputLabel>
              <Select
                labelId="type-filter-label"
                value={selectedType}
                label="Filter by Type"
                onChange={(e) => {
                  setSelectedType(e.target.value);
                  setPage(0);
                }}
              >
                <MenuItem value="all">All Types</MenuItem>
                {operatorTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* Right side: Action buttons */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {hasActionPermission('operators', 'new_inventory', userRole) && (
              <Button
                variant="contained"
                startIcon={<AddIcon sx={{ fontSize: "20px" }} />}
                onClick={() => setOpenOperatorModal(true)}
                sx={{
                  textTransform: "none",
                  px: 2,
                  py: 0.75,
                  height: "36px",
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  }
                }}
              >
                New Operator
              </Button>
            )}
          </Box>
        </Box>

        {/* Separator Line */}
        <Box sx={{
          height: "1px",
          backgroundColor: "#e0e0e0",
          width: "100%",
          mx: -2,
          width: "calc(100% + 32px)",
          mb: 2
        }} />

        {/* Table */}
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: (theme) => `${theme.palette.primary.main}08` }}>
                <TableCell sx={{
                  fontWeight: 500,
                  color: "#6B7280",
                  borderBottom: "1px solid #E5E7EB",
                  py: 1.5
                }}>
                  <TableSortLabel
                    active={orderBy === "operatorName"}
                    direction={orderBy === "operatorName" ? order : "asc"}
                    onClick={() => handleSort("operatorName")}
                  >
                    Operator Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{
                  fontWeight: 500,
                  color: "#6B7280",
                  borderBottom: "1px solid #E5E7EB",
                  py: 1.5
                }}>
                  <TableSortLabel
                    active={orderBy === "operatorType"}
                    direction={orderBy === "operatorType" ? order : "asc"}
                    onClick={() => handleSort("operatorType")}
                  >
                    Operator Type
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{
                  fontWeight: 500,
                  color: "#6B7280",
                  borderBottom: "1px solid #E5E7EB",
                  py: 1.5
                }}>
                  <TableSortLabel
                    active={orderBy === "centralSpocName"}
                    direction={orderBy === "centralSpocName" ? order : "asc"}
                    onClick={() => handleSort("centralSpocName")}
                  >
                    Central SPOC Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{
                  fontWeight: 500,
                  color: "#6B7280",
                  borderBottom: "1px solid #E5E7EB",
                  py: 1.5
                }}>
                  <TableSortLabel
                    active={orderBy === "deposit"}
                    direction={orderBy === "deposit" ? order : "asc"}
                    onClick={() => handleSort("deposit")}
                  >
                    Deposit
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{
                  fontWeight: 500,
                  color: "#6B7280",
                  borderBottom: "1px solid #E5E7EB",
                  py: 1.5
                }}>
                  <TableSortLabel
                    active={orderBy === "lockinPeriod"}
                    direction={orderBy === "lockinPeriod" ? order : "asc"}
                    onClick={() => handleSort("lockinPeriod")}
                  >
                    Lock-in Period
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{
                  fontWeight: 500,
                  color: "#6B7280",
                  borderBottom: "1px solid #E5E7EB",
                  py: 1.5
                }}>
                  <TableSortLabel
                    active={orderBy === "noticePeriod"}
                    direction={orderBy === "noticePeriod" ? order : "asc"}
                    onClick={() => handleSort("noticePeriod")}
                  >
                    Notice Period
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{
                  fontWeight: 500,
                  color: "#6B7280",
                  borderBottom: "1px solid #E5E7EB",
                  py: 1.5
                }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center" sx={{ py: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                      <CircularProgress size={20} />
                      <Typography variant="body2" color="text.secondary">
                        Fetching Operators...
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : paginatedOperators.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center" sx={{ py: 3 }}>
                    <Typography variant="body2" color="text.secondary">
                      No operators found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                paginatedOperators.map((operator) => (
                  <TableRow
                    key={operator.id}
                    hover
                    sx={{
                      "&:hover": {
                        backgroundColor: "#F5F3FF",
                        cursor: "pointer"
                      },
                      "& .MuiTableCell-root": {
                        py: 1.5
                      }
                    }}
                  >
                    <TableCell sx={{
                      borderBottom: "1px solid #E5E7EB",
                      color: "#111827",
                      py: 1.5
                    }}>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {operator.operatorName}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{
                      borderBottom: "1px solid #E5E7EB",
                      color: "#111827",
                      py: 1.5
                    }}>
                      <Typography variant="body1">
                        {operator.operatorType}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{
                      borderBottom: "1px solid #E5E7EB",
                      color: "#111827",
                      py: 1.5
                    }}>
                      <Typography variant="body1">
                        {operator.centralSpocName}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{
                      borderBottom: "1px solid #E5E7EB",
                      color: "#111827",
                      py: 1.5
                    }}>
                      <Typography variant="body1">
                        {operator.deposit}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{
                      borderBottom: "1px solid #E5E7EB",
                      color: "#111827",
                      py: 1.5
                    }}>
                      <Typography variant="body1">
                        {operator.lockinPeriod}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{
                      borderBottom: "1px solid #E5E7EB",
                      color: "#111827",
                      py: 1.5
                    }}>
                      <Typography variant="body1">
                        {operator.noticePeriod}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{
                      borderBottom: "1px solid #E5E7EB",
                      color: "#111827",
                      py: 1.5
                    }}>
                      {renderActions({ row: operator })}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedOperators.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              borderTop: "1px solid #e0e0e0",
              display: "flex",
              alignItems: "center",
              ".MuiTablePagination-toolbar": {
                display: "flex",
                alignItems: "center",
                gap: 2,
              },
              ".MuiTablePagination-displayedRows": {
                margin: 0,
              },
              ".MuiTablePagination-selectLabel": {
                margin: 0,
              },
              ".MuiTablePagination-select": {
                borderRadius: 1,
                border: "1px solid #e0e0e0",
                marginLeft: 1,
                marginRight: 2,
                paddingTop: 0.5,
                paddingBottom: 0.5,
              },
              ".MuiTablePagination-actions": {
                marginLeft: 2,
                display: "flex",
                gap: 1,
              }
            }}
          />
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {/* Header */}
          <Box sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #E5E7EB'
          }}>
            <Typography variant="h6" component="h2">
              Operator Details
            </Typography>
            <IconButton
              onClick={handleCloseModal}
              size="small"
              sx={{
                color: 'text.secondary',
                '&:hover': {
                  color: 'text.primary',
                  bgcolor: 'rgba(0, 0, 0, 0.04)'
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Content */}
          {selectedOperator && (
            <Box sx={{ p: 3 }}>
              <Grid container spacing={3}>
                {/* Basic Information */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                    Basic Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>Operator Name</Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedOperator.operatorName || 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>Operator Type</Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedOperator.operatorType || 'N/A'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Contact Information */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                    Contact Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>Central SPOC Name</Typography>
                      <Typography variant="body1">{selectedOperator.centralSpocName || 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>Central SPOC Email</Typography>
                      <Typography variant="body1">{selectedOperator.centralSpocEmail || 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>Central SPOC Phone</Typography>
                      <Typography variant="body1">{selectedOperator.centralSpocPhoneNumber || 'N/A'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Terms & Conditions */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                    Terms & Conditions
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>Annual Escalation</Typography>
                      <Typography variant="body1">{selectedOperator.annualEscalation || 'N/A'}%</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>Security Deposit (months)</Typography>
                      <Typography variant="body1">{selectedOperator.deposit || 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>Lock-in Period (months)</Typography>
                      <Typography variant="body1">{selectedOperator.lockinPeriod || 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>Notice Period (months)</Typography>
                      <Typography variant="body1">{selectedOperator.noticePeriod || 'N/A'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Additional Information */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                    Additional Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>Corporate Profile</Typography>
                      <Typography variant="body1" sx={{
                        p: 1.5,
                        bgcolor: 'grey.50',
                        borderRadius: 1,
                        minHeight: '60px'
                      }}>
                        {selectedOperator.corporateProfile || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>Registered Address</Typography>
                      <Typography variant="body1" sx={{
                        p: 1.5,
                        bgcolor: 'grey.50',
                        borderRadius: 1,
                        minHeight: '60px'
                      }}>
                        {selectedOperator.registeredAddress || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>Standard Terms</Typography>
                      <Typography variant="body1" sx={{
                        p: 1.5,
                        bgcolor: 'grey.50',
                        borderRadius: 1,
                        minHeight: '60px'
                      }}>
                        {selectedOperator.standardTerms || 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Modal>
      {/* Import Inventory Modal */}
      <ImportInventoryModal
        open={isImportModalOpen}
        onClose={handleImportClose}
        onImport={handleImport}
        onEditItem={handleEditItem}
        operatorName={selectedOperator?.operatorName || ''}
        operatorId={selectedOperator?.id || ''}
        centerInventoryData={importData}
        theme={theme}
      />
      <OperatorForm
        open={openOperatorModal}
        onClose={() => {
          setOpenOperatorModal(false);
          setEditMode(false);
          setSelectedOperator(null);
        }}
        onSubmit={handleOperatorSubmit}
        initialData={editMode ? selectedOperator : null}
        editMode={editMode}
        theme={theme}
      />
    </Card>
  );
};

export default Operators;
