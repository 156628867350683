import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    cities: [],
    micromarkets: [],
    features: {
      enableAIChat: false
    }
  },
  loading: false,
  error: null
};

const metaSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setMeta: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

// Selectors
export const selectCities = state => state.meta.data?.cities || [];

export const selectMicromarkets = state => state.meta.data?.micromarkets || [];

export const selectFeatures = state => state.meta.data?.features || { enableAIChat: false };

export const selectMicromarketsByCity = state => {
  const micromarkets = state.meta.data?.micromarkets || [];
  return micromarkets.reduce((acc, m) => {
    if (!acc[m.city]) {
      acc[m.city] = [];
    }
    acc[m.city].push(m.micromarket);
    return acc;
  }, {});
};

export const selectIsLoading = state => state.meta.loading;

export const selectError = state => state.meta.error;

export const { setMeta, setLoading, setError } = metaSlice.actions;

export default metaSlice.reducer;
