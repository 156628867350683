import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Details from "../../Pages/Public/Details";
import backicon from "../../assest/image/back.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faCalendarAlt, faCommentDots } from "@fortawesome/free-solid-svg-icons";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import NotesIcon from '@mui/icons-material/Notes';
import { Modal, Button, Form, Toast, ToastContainer } from 'react-bootstrap';
import { Typography, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import faClipboard from "../../assest/image/1.png";
import faCalendar from "../../assest/image/2.png";
import faInfoCircle from "../../assest/image/3.png";
import Clipboard from "../../assest/image/4.png";
import Notes from "../../assest/image/5.png";
import Amenities from "../../assest/image/meeting-place.png";
import Loactionicon from "../../assest/image/location-pin.png";
import { auth, db } from '../../firebase/config';
import { doc, updateDoc, arrayUnion, getDoc, Timestamp } from 'firebase/firestore';
import ShortlistTable from '../../Component/Public/Shortlist Place/ShortlistTable';
import '../../Styles/Centerdetails.css';
import { addShortlistNotification } from '../../Services/FirebaseUtils';

const Centerdetails = ({
    centerName,
    inventoryDescription,
    billableSeats,
    pricePerSeat,
    totalRentPerMonth,
    amenities,
    termsAndConditions,
    location,
    layouts,
    photos,
    availableFrom,
    securityDeposit,
    virtualTours,
    bikeParkingCost,
    carParkingCost,
    option,
    proposalId,
    shortlistedCenters, // New prop
    isEditable, // New prop
    id
}) => {

    // console.log(proposalId,"center details")

    const [isShortlisted, setIsShortlisted] = useState(false);
    const [shortlistedUsers, setShortlistedUsers] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    // Feedback state
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [feedbackText, setFeedbackText] = useState('');
    const [notesText, setNotesText] = useState('');
    const [feedbackSent, setFeedbackSent] = useState(false);
    const [notesSent, setNotesSent] = useState(false);

    const address = location?.address || '';

    // Update shortlist status when shortlistedCenters prop changes
    useEffect(() => {
        if (!auth.currentUser || !shortlistedCenters) return;

        const centerShortlist = shortlistedCenters[centerName] || [];
        
        // Check if current user has shortlisted
        const userShortlisted = centerShortlist.some(
            user => user.email === auth.currentUser.email
        );

        setIsShortlisted(userShortlisted);
        setShortlistedUsers(centerShortlist);
    }, [shortlistedCenters, centerName, auth.currentUser]);

    const handleShortlist = async () => {
        if (!auth.currentUser || !proposalId) {
            console.error('User not authenticated or proposal ID missing');
            return;
        }

        try {
            const proposalRef = doc(db, 'proposals', proposalId);
            const proposalDoc = await getDoc(proposalRef);

            if (proposalDoc.exists()) {
                const data = proposalDoc.data();
                
                // Ensure shortlistedItems is always an array
                const shortlistedItems = Array.isArray(data.shortlist) ? data.shortlist : [];

                // Find if center is already in shortlist
                const existingItemIndex = shortlistedItems.findIndex(
                    item => item.inventoryId === centerName
                );

                if (existingItemIndex !== -1) {
                    // Center exists in shortlist
                    const existingItem = shortlistedItems[existingItemIndex];

                    if (isShortlisted) {
                        // Remove current user from shortlistedBy
                        const updatedShortlistedBy = existingItem.shortlistedBy.filter(
                            user => user.email !== auth.currentUser.email
                        );

                        if (updatedShortlistedBy.length === 0) {
                            // Remove entire item if no users left
                            shortlistedItems.splice(existingItemIndex, 1);
                            
                            // Remove visit schedule for this center
                            const visitSchedule = data.visitSchedule || [];
                            const updatedSchedule = visitSchedule.filter(
                                schedule => schedule.inventoryName !== centerName
                            );
                            
                            // Update Firestore with both changes
                            await updateDoc(proposalRef, {
                                shortlist: shortlistedItems,
                                visitSchedule: updatedSchedule
                            });
                        } else {
                            // Update shortlistedBy array
                            existingItem.shortlistedBy = updatedShortlistedBy;
                            shortlistedItems[existingItemIndex] = existingItem;
                            
                            // Update only shortlist
                            await updateDoc(proposalRef, {
                                shortlist: shortlistedItems
                            });
                        }
                    } else {
                        // Add current user to existing shortlistedBy array
                        existingItem.shortlistedBy.push({
                            email: auth.currentUser.email,
                            timeStamp: Timestamp.now()
                        });
                        shortlistedItems[existingItemIndex] = existingItem;
                        
                        // Add notification when shortlisting
                        await addShortlistNotification(
                            proposalId,
                            auth.currentUser.email,
                            centerName
                        );
                    }
                } else if (!isShortlisted) {
                    // Add new shortlist item
                    shortlistedItems.push({
                        inventoryId: centerName,
                        shortlistedBy: [{
                            email: auth.currentUser.email,
                            timeStamp: Timestamp.now()
                        }]
                    });
                    
                    // Add notification for new shortlist
                    await addShortlistNotification(
                        proposalId,
                        auth.currentUser.email,
                        centerName
                    );
                }

                // Update Firestore
                if (!isShortlisted) {
                    await updateDoc(proposalRef, {
                        shortlist: shortlistedItems
                    });
                }

                // Update local state
                setIsShortlisted(!isShortlisted);
                setShortlistedUsers(
                    isShortlisted
                        ? shortlistedUsers.filter(user => user.email !== auth.currentUser.email)
                        : [...shortlistedUsers, { email: auth.currentUser.email, timeStamp: Timestamp.now() }]
                );
            }
        } catch (error) {
            console.error('Error updating shortlist:', error);
        }
    };

    const handleFeedback = async () => {
        if (!auth.currentUser || !proposalId || !feedbackText) {
            console.error('User not authenticated, proposal ID missing, or question empty');
            return;
        }

        try {
            const proposalRef = doc(db, 'proposals', proposalId);
            const proposalDoc = await getDoc(proposalRef);
            
            if (proposalDoc.exists()) {
                const data = proposalDoc.data();
                
                // Ensure feedback array exists
                if (!data.feedback) {
                    await updateDoc(proposalRef, { feedback: [] });
                    data.feedback = [];
                }
                let centerFeedbackIndex = -1;
                //Find out if the feedabck for the center exists
                for (let i = 0; i < data.feedback.length; i++) {
                    if (data.feedback[i].inventoryId === id) {
                        centerFeedbackIndex = i;
                        break;
                    }
                }
                if (centerFeedbackIndex !== -1) {
                    // If feedback for center exists add the new feedback to the feedbackBy array
                    if (!data.feedback[centerFeedbackIndex].feedbackItem) {
                        data.feedback[centerFeedbackIndex].feedbackItem = [];
                    }
                    data.feedback[centerFeedbackIndex].feedbackItem.push({
                        type: 'question',
                        text: feedbackText,
                        email: auth.currentUser.email,
                        timestamp: Timestamp.now()
                    });
                    
                } else {
                    // If feedback for center does not exist create a new feedback item
                    data.feedback.push({
                        inventoryId: id,
                        feedbackItem: [{
                            type: 'question',
                            text: feedbackText,
                            email: auth.currentUser.email,
                            timestamp: Timestamp.now()
                        }]
                    });
                }
                // Update Firestore
                await updateDoc(proposalRef, {
                    feedback: data.feedback
                });
                setFeedbackSent(true);
                setFeedbackText('');
                setTimeout(() => {
                    setShowFeedbackModal(false);
                    setFeedbackSent(false);
                }, 1500);
            }
        } catch (error) {
            console.error('Error adding feedback:', error);
        }
    };

    const handleNotes = async () => {
        if (!auth.currentUser || !proposalId || !notesText) {
            console.error('User not authenticated, proposal ID missing, or notes empty');
            return;
        }

        try {
            const proposalRef = doc(db, 'proposals', proposalId);
            const proposalDoc = await getDoc(proposalRef);
            
            if (proposalDoc.exists()) {
                const data = proposalDoc.data();
                
                // Ensure notes array exists
                if (!data.notes) {
                    await updateDoc(proposalRef, { notes: [] });
                    data.notes = [];
                }
                let centerNoteIndex = -1;
                //Find out if the note for the center exists
                for (let i = 0; i < data.notes.length; i++) {
                    if (data.notes[i].inventoryId === id) {
                        centerNoteIndex = i;
                        break;
                    }
                }

                if (centerNoteIndex !== -1) {
                    // If note for center exists add the new note to the notesBy array
                    if (!data.notes[centerNoteIndex].notesBy) {
                        data.notes[centerNoteIndex].notesBy = [];
                    }
                    data.notes[centerNoteIndex].notesBy.push({
                        text: notesText,
                        email: auth.currentUser.email,
                        timestamp: Timestamp.now()
                    });
                    
                } else {
                    // If note for center does not exist create a new note item
                    data.notes.push({
                        inventoryId: id,
                        notesBy: [{
                            text: notesText,
                            email: auth.currentUser.email,
                            timestamp: Timestamp.now()
                        }]
                    });
                }

                // Update Firestore
                await updateDoc(proposalRef, {
                    notes: data.notes
                });

                setNotesSent(true);
                setNotesText('');
                setTimeout(() => {
                    setShowNotesModal(false);
                    setNotesSent(false);
                }, 1500);
            }
        } catch (error) {
            console.error('Error adding notes:', error);
        }
    };

    const formatPrice = (price) =>
        price !== undefined && price !== null
            ? new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR", maximumFractionDigits: 0 }).format(price)
            : "N/A";

    const theme = useTheme();

    const handleBackClick = (event) => {
        event.preventDefault();
        const element = document.getElementById('recommended-spaces');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <Box className="mt-5" id="Details">
            <div className="container">
                <div className="row gy-4">
                    {/* Left Column */}
                    <div className="col-md-6">
                        <Typography variant="h4" component="h2" gutterBottom id={`${centerName}`} sx={{ mb: 1 }}>
                            <span 
                                onClick={handleBackClick}
                                style={{ cursor: 'pointer' }}
                                role="button"
                                tabIndex={0}
                            >
                                <img src={backicon} width="20px" alt="Back" className="me-2" />
                            </span>
                            {centerName}
                        </Typography>
                        <Box className="address text-start">
                            <div className="row">
                                <div className="col-1">
                                    <img src={Loactionicon} width="20px" alt="Location" className="m-2" />
                                </div>
                                <div className="col-11 px-3">
                                    <Typography variant="body1">{address}</Typography>
                                </div>
                            </div>
                        </Box>
                        <Details location={location} layouts={layouts} photos={photos} virtualTours={virtualTours} />
                        <div className="col-md-12 mt-4">
                            {!isEditable && (
                                <div className="d-flex flex-wrap justify-content-center mb-3">
                                    <button
                                        className="CentercardButton mb-2"
                                        onClick={handleShortlist}
                                        title={isShortlisted ? 'Click to remove from shortlist' : 'Click to shortlist'}
                                    >
                                        <FontAwesomeIcon
                                            icon={faHeart}
                                            className={`me-2 ${isShortlisted ? 'text-danger' : 'text-secondary'}`}
                                        />
                                        Shortlist
                                    </button>
                                    <button
                                        className="CentercardButton mb-2"
                                        onClick={() => setShowFeedbackModal(true)}
                                    >
                                        <QuestionMarkIcon className="me-2" />
                                        Questions
                                    </button>
                                    <button
                                        className="CentercardButton mb-2"
                                        onClick={() => setShowNotesModal(true)}
                                    >
                                        <NotesIcon className="me-2" />
                                        Notes
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="col-md-6 mb-5">
                        <Box>
                            <Box className="p-md-4 p-0 bg-light rounded p-2 option-details">
                                {/* Inventory Section */}
                                <Box mb={4}>
                                    <Typography variant="subtitle1" fontWeight="bold" className="tableicon-head">
                                        <img src={faClipboard} alt="Inventory" className="me-2" width={26} />
                                        Inventory
                                    </Typography>
                                    <Typography variant="body1" className="tableicon-content">
                                        <Box component="span" fontWeight="medium">Capacity:</Box> {billableSeats || "N/A"} billable seats <br />
                                        <Box component="span" fontWeight="medium">Operator:</Box> {option.operatorName} <br />
                                        {inventoryDescription && <div>{inventoryDescription}</div>}
                                    </Typography>
                                </Box>
                                <hr />
                                {/* Availability Section */}
                                <Box mb={4}>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        <img src={faCalendar} alt="Availability" className="me-2" width={26} />
                                        Availability
                                    </Typography>
                                    <Typography variant="body1" className="tableicon-content">
                                        {availableFrom}
                                    </Typography>
                                </Box>
                                <hr />
                                {/* Pricing Section */}
                                <Box mb={4}>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        <img src={faInfoCircle} alt="Pricing" className="me-2" width={26} />
                                        Pricing
                                    </Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                                <Box component="span" sx={{ mr: 1 }}>•</Box>
                                                <Box component="span" fontWeight="medium">Price per seat:</Box> {formatPrice(pricePerSeat)}/month
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                                <Box component="span" sx={{ mr: 1 }}>•</Box>
                                                <Box component="span" fontWeight="medium">Total rent:</Box> {formatPrice(totalRentPerMonth)}/month
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                                <Box component="span" sx={{ mr: 1 }}>•</Box>
                                                <Box component="span" fontWeight="medium">Security deposit:</Box> {formatPrice(securityDeposit)}
                                            </Typography>
                                        </Grid>
                                        {bikeParkingCost && (
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                                    <Box component="span" sx={{ mr: 1 }}>•</Box>
                                                    <Box component="span" fontWeight="medium">Bike parking:</Box> {formatPrice(bikeParkingCost)}/month
                                                </Typography>
                                            </Grid>
                                        )}
                                        {carParkingCost && (
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                                    <Box component="span" sx={{ mr: 1 }}>•</Box>
                                                    <Box component="span" fontWeight="medium">Car parking:</Box> {formatPrice(carParkingCost)}/month
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                                <hr />
                                {/* Amenities Section */}
                                {amenities && amenities.length > 0 && (
                                    <Box mb={4}>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            <img src={Amenities} alt="Amenities" className="me-2" width={26} />
                                            Amenities
                                        </Typography>
                                        <Grid container spacing={1}>
                                            {amenities.map((amenity, index) => (
                                                <Grid item xs={6} key={index}>
                                                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Box component="span" sx={{ mr: 1 }}>•</Box>
                                                        {amenity}
                                                    </Typography>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                )}
                                {amenities && amenities.length > 0 && <hr />}
                                {/* Terms and Conditions Section */}
                                <Box mb={4}>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        <img src={Clipboard} alt="Terms" className="me-2" width={26} />
                                        Terms & Conditions
                                    </Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                                <Box component="span" sx={{ mr: 1 }}>•</Box>
                                                <Box component="span" fontWeight="medium">Lock-in Period:</Box> {option.lockInPeriod || "N/A"} months
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                                <Box component="span" sx={{ mr: 1 }}>•</Box>
                                                <Box component="span" fontWeight="medium">Notice Period:</Box> {option.noticePeriod || "N/A"} months
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                                <Box component="span" sx={{ mr: 1 }}>•</Box>
                                                <Box component="span" fontWeight="medium">Annual Escalation:</Box> {option.annualEscalation || "N/A"}%
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                                <Box component="span" sx={{ mr: 1 }}>•</Box>
                                                <Box component="span" fontWeight="medium">Meeting Room Credits:</Box> {option.complimentaryMeetingRoomCredits || "N/A"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                                <Box component="span" sx={{ mr: 1 }}>•</Box>
                                                <Box component="span" fontWeight="medium">Car Parking:</Box> {option.complimentaryCarParkSlots || "N/A"} Slots
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                                <Box component="span" sx={{ mr: 1 }}>•</Box>
                                                <Box component="span" fontWeight="medium">Bike Parking:</Box> {option.complimentaryBikeParkSlots || "N/A"} Slots
                                            </Typography>
                                        </Grid>
                                        {option.terms && (
                                            <Grid item xs={12}>
                                                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'flex-start' }} gutterBottom>
                                                    <Box component="span" sx={{ mr: 1 }}>•</Box>
                                                    <Box component="span" fontWeight="medium">Additional Terms:</Box> {option.terms}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                                <hr />
                                {/* Notes Section */}
                                <Box mb={4}>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        <img src={Notes} alt="Notes" className="me-2" width={26} />
                                        Notes
                                    </Typography>
                                    <Box>
                                        {option.floor && (
                                            <Typography variant="body1" gutterBottom>
                                                <Box component="span" fontWeight="medium">Floor:</Box> {option.floor}
                                            </Typography>
                                        )}
                                        {option.nearestMetro && (
                                            <Typography variant="body1" gutterBottom>
                                                <Box component="span" fontWeight="medium">Nearest Metro:</Box> {option.nearestMetro}
                                            </Typography>
                                        )}
                                        {option.operationHours && (
                                            <Typography variant="body1" gutterBottom>
                                                <Box component="span" fontWeight="medium">Operating Hours:</Box> {option.operationHours}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>

            {/* Questions Modal */}
            <Modal show={showFeedbackModal} onHide={() => setShowFeedbackModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Questions about {centerName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Ask a question about this space:</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={feedbackText}
                                onChange={(e) => setFeedbackText(e.target.value)}
                                placeholder="What would you like to know about this space?"
                            />
                        </Form.Group>
                    </Form>
                    {feedbackSent && (
                        <div className="alert alert-success mt-3">
                            Question submitted successfully!
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ backgroundColor: '#1F497D' }} onClick={() => setShowFeedbackModal(false)}>
                        Close
                    </Button>
                    <Button style={{ backgroundColor: '#1F497D' }} onClick={handleFeedback}>
                        Submit Question
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Notes Modal */}
            <Modal show={showNotesModal} onHide={() => setShowNotesModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Notes for {centerName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Add your notes about this space:</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={notesText}
                                onChange={(e) => setNotesText(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                    {notesSent && (
                        <div className="alert alert-success mt-3">
                            Notes saved successfully!
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ backgroundColor: '#1F497D' }} onClick={() => setShowNotesModal(false)}>
                        Close
                    </Button>
                    <Button style={{ backgroundColor: '#1F497D' }} onClick={handleNotes}>
                        Save Notes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Feedback Toast */}
            <ToastContainer position="bottom-end">
                <Toast show={feedbackSent} onClose={() => setFeedbackSent(false)} delay={3000} autohide>
                    <Toast.Header>
                        <Typography variant="subtitle2" sx={{ flex: 1 }}>Success</Typography>
                    </Toast.Header>
                    <Toast.Body>
                        <Typography variant="body2">Your feedback has been submitted successfully!</Typography>
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            {/* Toast */}
            <ToastContainer position="bottom-end">
                <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header>
                        <Typography variant="subtitle2" sx={{ flex: 1 }}>Success</Typography>
                    </Toast.Header>
                    <Toast.Body>
                        <Typography variant="body2">{toastMessage}</Typography>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </Box>
    );
};

export default Centerdetails;
