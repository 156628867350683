import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Badge,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import NotificationPopover from '../NotificationPopover';
import ActionItemsCalendar from '../ActionItemsCalendar';
import { db } from '../../../firebase/config';
import { query, collection, onSnapshot, orderBy } from 'firebase/firestore';
import { markNotificationAsRead } from '../../../Services/FirebaseUtils';
import { useSelector } from 'react-redux';
import { hasMenuPermission } from '../../../utils/rolePermissions';

const drawerWidth = 240;

function Navbar({ onMenuClick, pageTitle }) {
  const [notifications, setNotifications] = useState([]);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const user = useSelector((state) => state.user.data);
  const userRole = user?.role || '';

  useEffect(() => {
    const q = query(collection(db, "notifications"), orderBy("timestamp", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const notificationsList = [];
      querySnapshot.forEach((doc) => {
        notificationsList.push({ id: doc.id, ...doc.data() });
      });
      setNotifications(notificationsList.filter(notification => !notification.read));
    });

    return () => unsubscribe();
  }, []);

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  return (
    <AppBar 
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: '#ffffff',
        color: '#000000',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onMenuClick}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, color: '#000000' }}
          >
            {pageTitle}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {hasMenuPermission('calendar', userRole) && (
              <ActionItemsCalendar />
            )}
            {hasMenuPermission('notifications', userRole) && (
              <IconButton
                size="large"
                aria-label="show new notifications"
                color="inherit"
                onClick={handleNotificationClick}
              >
                <Badge badgeContent={notifications.length} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            )}
          </Box>

          {hasMenuPermission('notifications', userRole) && (
            <NotificationPopover
              anchorEl={notificationAnchorEl}
              onClose={handleNotificationClose}
              notifications={notifications}
              onNotificationRead={markNotificationAsRead}
            />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
